#customized {
  .customized {
    &-teaser-container {
      padding-top: 33px;
      padding-bottom: 31px;

      @include mq('large') {
        padding-top: 54px;
        padding-bottom: 54px;
      }

      .aaaem-teaser__subtitle {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        font-weight: var(--emu-common-font-weight-bold);
        letter-spacing: 0.54px;
        margin-top: 14px;
        margin-bottom: 14px;

        @include mq('large') {
          font-size: 21px;
          line-height: 31.5px;
          letter-spacing: 2.1px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .cmp-teaser__description {
        font-size: var(--emu-semantic-font-sizes-wide-small);
        line-height: var(--emu-common-line-heights-narrow-xl);
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: 0.03px;
        max-width: 68%;

        @include mq('large') {
          max-width: 70%;
        }

        @include mq('x-large') {
          max-width: 44%;
        }

        p {
          margin-bottom: 24px;
          letter-spacing: -0.48px;

          @include mq('large') {
            margin-bottom: 36px;
            letter-spacing: -0.72px;
          }

          &:last-child {
            margin-bottom: var(--emu-common-sizing-none);
          }
        }
      }

      .aaaem-image img {
        margin: auto;
        width: 100%;
        max-height: 478px;
        object-fit: cover;
        padding-left: 30px;
        padding-right: 30px;

        @include mq('large') {
          max-height: 100%;
          padding-left: var(--emu-common-spacing-xl);
          padding-right: var(--emu-common-spacing-xl);
        }
      }
    }
  }

  .dg-product-showcase {
    padding-bottom: 29px;

    @include mq('large') {
      padding-bottom: var(--emu-common-spacing-small);
    }

    &__subtitle {
      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        letter-spacing: 0.54px;

        @include mq('large') {
          font-size: 21px;
          line-height: 31.5px;
          letter-spacing: 2.1px;
        }
      }
    }

    &__text[data-component='text'] {
      @include mq('large') {
        max-width: 750px;
      }
    }

    &__carousel {
      margin-top: 44px;

      @include mq('large') {
        margin-top: 65px;
      }

      .aaaem-image {
        img {
          width: 100%;
        }
      }
    }

    .aaaem-carousel {
      &__actions {
        right: 31px;

        @include mq('large') {
          right: initial;
        }
      }
    }

    .product-card__text {
      margin-bottom: var(--emu-common-spacing-xs);

      @include mq('large') {
        margin-bottom: 20px;
      }

    }
  }

  .gallery-redirector {
    &__wrapper {
      margin-bottom: var(--emu-common-spacing-none);
      padding-bottom: 42px;

      @include mq('large') {
        padding-bottom: 51px;
      }
    }

    &__text-content {
      @include mq('large') {
        width: 426px;
      }
    }

    &__cta {
      min-width: 228px;
  
      @include mq('large') {
        min-width: 285px;
      }
    }  

    &__disclaimer-text {
      letter-spacing: -0.36px;
      max-width: 320px;

      @include mq('large') {
        max-width: 582px;
        letter-spacing: normal;
      }

      p {
        @include mq('large') {
          padding-bottom: 10px;
        }
      }
    }
  }

  .dg-testimonial__container {
    background-color: var(--emu-semantic-colors-secondary-50);
  }
}
