#faq {
  .faq {
    &__accordion-container {
      padding-top: 35px;
      padding-right: 24px;
      padding-bottom: 63px;
      padding-left: 24px;
      background-color: var(--emu-semantic-colors-secondary-50);

      @include mq('large') {
        padding-top: 114px;
        padding-right: var(--emu-common-spacing-xl);
        padding-bottom: 114px;
        padding-left: var(--emu-common-spacing-xl);
      }

      .aaaem-accordion__panel {
        @include mq('large') {
          max-width: 80%; // to match the design.
        }

        .aaaem-text {
          .emphasis {
            display: inline-block;
          }
        }
      }
    }

    &__title {
      .cmp-title__text {
        font-size: 55px;
        line-height: 55px;
        letter-spacing: -1.65px; // to match the design.
        margin-bottom: 19px;

        @include mq('large') {
          font-size: 66px;
          line-height: 66px;
          letter-spacing: -1.98px;
          margin-bottom: 8px;
        }
      }
    }

    &__text {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      letter-spacing: -0.54px; // to match the design.
      margin-bottom: 10px;

      @include mq('large') {
        font-size: 29px;
        line-height: 43.5px;
        letter-spacing: -0.87px;
        margin-bottom: var(--emu-common-spacing-large);
      }
    }
  }
}
