/** 
 * This component acts as a top-level banner in all pages except Homepage.
 * Design - https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-360&mode=design&t=xvgGiZm99hNbLW67-4
 */

.aaaem-teaser {
  .content-container {
    position: absolute;
    top: 0;
  }

  &__title.cmp-teaser__title {
    font-size: 50px; //as per design
    line-height: var(--emu-semantic-line-heights-narrow-xl);
    letter-spacing: -1.5px; //as per design
    text-shadow: var(--emu-common-spacing-none) var(--emu-common-spacing-xs)
      var(--emu-common-spacing-xs) rgba(0, 0, 0, 0.25); // as per design
    padding-top: var(--emu-common-spacing-medium);
    padding-left: 18px; //as per design
    padding-right: 18px; //as per design

    @include mq('large') {
      font-size: 80px; //as per design
      line-height: 80px; //as per design
      letter-spacing: -2.4px; //as per design
      padding-bottom: 24px; //as per design
    }

    bdi {
      display: inline-block;
    }
  }

  .disclaimer {
    width: fit-content;
    bottom: var(--emu-common-spacing-medium);
    left: 28px; // as per design
    position: absolute;

    @include mq('large') {
      bottom: 19px; // as per design
      left: 26px; // as per design
    }

    p {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: var(--emu-common-line-heights-narrow-medium);
      letter-spacing: -0.36px; //as per design
      margin: var(--emu-common-spacing-none);

      @include mq('large') {
        font-size: 14px; //as per design
        line-height: 21px; //as per design
        letter-spacing: -0.42px; //as per design
      }
    }
  }

  // to override default clientlib breakpoint for image
  .teaser-image-container {
    .cmp-teaser__image {
      &--mobile {
        display: block;

        @include mq('large') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include mq('large') {
          display: block;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
