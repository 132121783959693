/** 
 * This component displays a carousel of products to the user. It is present in Home and Customized For You pages.
 * Design: https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-78&mode=design&t=xvgGiZm99hNbLW67-4
 */

.dg {
  &-product-showcase {
    padding-top: 39px; //as per design
    padding-bottom: 31px; //as per design

    @include mq('large') {
      padding-top: 79px; //as per design
    }

    &__title .cmp-title__text {
      letter-spacing: -0.8px; //as per design
      margin-left: var(--emu-common-spacing-large);
      margin-right: var(--emu-common-spacing-large);

      @include mq('large') {
        letter-spacing: -1.2px; //as per design
      }

      sup {
        font-size: 60%; //as per design
      }
    }

    &__subtitle {
      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: 0.45px; //as per design
        margin-left: 48px; //as per design
        margin-right: 48px; //as per design
        margin-top: 14px; //as per design
        margin-bottom: 14px; //as per design
        font-weight: var(--emu-common-font-weight-bold);
        font-family: var(--emu-semantic-font-families-body);

        @include mq('large') {
          font-size: 21px; //as per design
          line-height: 31.5px; //as per design
          letter-spacing: 0.63px; //as per design
          margin-top: 10px; //as per design
          margin-bottom: 10px; //as per design
        }
      }
    }

    &__text[data-component='text'] {
      margin-left: 48px; //as per design
      margin-right: 48px; //as per design

      @include mq('large') {
        max-width: 600px; //as per design
        margin-left: auto;
        margin-right: auto;
      }

      p {
        letter-spacing: -0.48px; //as per design

        @include mq('large') {
          letter-spacing: -0.72px; //as per design
        }
      }
    }

    &__cta[data-component='button'] {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      letter-spacing: 3.6px; //as per design
      min-width: 228px; //as per design
      padding-top: 11.5px; //as per design
      padding-bottom: 11.5px; //as per design
      padding-left: var(--emu-common-spacing-small);
      padding-right: var(--emu-common-spacing-small);
      margin-top: 22px; //as per design

      @include mq('large') {
        min-width: 239px; //as per design
        padding-top: 15.5px; //as per design
        padding-bottom: 15.5px; //as per design
        margin-top: 34px; //as per design
      }
    }

    .product-card {
      &__img--stamp {
        img {
          width: 59px;
          right: 24px; //as per design
          bottom: 20px; //as per design

          @include mq('large') {
            width: 76px; //as per design
          }
        }

        &-big img {
          width: 87px;
          right: 16px; //as per design
          bottom: 10px; //as per design

          @include mq('large') {
            width: 111px; //as per design
          }
        }
      }

      &__title .cmp-title__text {
        margin-top: var(--emu-common-spacing-xxs);
      }

      &__text {
        margin-top: var(--emu-common-spacing-small);
        max-width: 274px; //as per design
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
