// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

.container--root {
  margin-top: 81px; // to match the design.
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    margin-top: 140px;
  }
}

[data-component='title'],
[data-component='text'] {
  &.text--color-brown-50 {
    color: var(--emu-semantic-colors-primary-50);
  }
}

.bg--light-grey-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.bg--light-grey-100 {
  background-color: var(--emu-semantic-colors-secondary-100);
}

.animated-section {
  opacity: 0; /* Start with the section hidden */
  transition: none;

  // author mode styles
  @include aem-editor-view {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }

  &--animated {
    opacity: 1; /* Start with the section hidden */
    transition: all var(--emu-common-other-time-transition-base) linear;
  }

  &--scale {
    transform: scale(0);

    &.animated-section--animated {
      transform: scale(1);
    }
  }

  &--fade-in-up {
    transform: translateY(50px);
    opacity: 0;

    &.animated-section--animated {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// duration classes from 100ms to 1000ms separated by 100ms apart
@for $i from 1 through 10 {
  .duration--#{$i * 100}ms {
    transition-duration: #{$i *
      100}ms !important; // needed to override the default values
    animation-duration: #{$i *
      100}ms !important; // needed to override the default values
  }
}

// delay classes from 100ms to 1000ms separated by 100ms apart
@for $i from 1 through 10 {
  .delay--#{$i * 100}ms {
    transition-delay: #{$i *
      100}ms !important; // needed to override the default values
    animation-delay: #{$i *
      100}ms !important; // needed to override the default values
  }
}

// Adding utility for images that require aspect ratio of 16/11.
.aspect-16-11 {
  aspect-ratio: 16 / 11;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Adding utility to hide isi--consumer in the HCP page and isi--hcp in the consumer page.
.consumer-page {
  .isi--hcp {
    display: none;
  }
}

.hcp-page {
  .isi--consumer {
    display: none;
  }
}

.main-banner-teaser {
  min-height: 170px;
  aspect-ratio: 131 / 160;

  @include mq('large') {
    min-height: 280px; // Adding to fix the overlapping issue.
    aspect-ratio: 144 / 55;
  }
}
