#home {
  .home {
    &__banner-carousel {
      margin-bottom: 22px;

      @include mq('large') {
        margin-bottom: 43px;
      }

      .aaaem-carousel {
        margin: var(--emu-common-spacing-none);

        &__actions {
          display: none;
        }

        &__indicators {
          position: relative;
          bottom: 25px;
          gap: 5px;

          @include mq('large') {
            bottom: 50px;
            gap: 10px;
          }
        }

        &__indicator {
          background-color: var(--emu-common-colors-white);
          width: 5px;
          height: 5px;
          opacity: 0.5;
          &.tns-nav-active {
            opacity: 1;
          }

          @include mq('large') {
            width: 12px;
            height: 12px;
          }
        }

        &__item-container {
          position: relative;
        }
      }

      &-content-wrapper {
        width: 100%;
        position: absolute;
        top: 0;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        @include mq('large') {
          height: 100%;
        }

        @include mq('x-large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }

        &--first {
          display: flex;
          flex-direction: column;
          gap: var(--emu-common-spacing-large);

          @include mq('large') {
            flex-direction: row;
            gap: 120px;
          }

          .home__banner-carousel {
            &-text-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 26px;
              margin-top: 11px;

              @include mq('large') {
                margin-top: 36px;
                gap: var(--emu-common-spacing-large);
              }

              h2 {
                font-size: 23px;
                line-height: 20px;
                font-weight: var(--emu-common-font-weight-light);
                letter-spacing: -0.46px;

                @include mq('large') {
                  font-size: 37px;
                  line-height: 60px;
                  letter-spacing: -0.74px;
                }

                .emphasis {
                  font-size: 70px;
                  line-height: 84px;
                  letter-spacing: -1.4px;

                  @include mq('x-large') {
                    font-size: 148px;
                    line-height: 178px;
                    letter-spacing: -2.96px;
                    max-height: 150px;
                    display: flex;
                    align-items: center;
                  }
                }
              }
            }

            &-image-content {
              max-width: 337px;

              @include mq('large') {
                max-width: 599px;
                margin-top: 30px;
              }

              // to match figma and prevent cut-off in smaller resolutions
              @include mq('1350px') {
                transform: translateX(32px);
              }

              img {
                width: 100%;
                object-fit: cover;
              }
            }

            &-image-disclaimer {
              font-size: var(--emu-semantic-font-sizes-narrow-xs);
              line-height: 18px;
              margin-top: var(--emu-common-spacing-small);
              letter-spacing: -0.36px;

              @include mq('large') {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.42px;
              }
            }
          }
        }

        &--second {
          display: flex;
          flex-direction: column;

          .home__banner-carousel {
            &-text-content {
              margin-bottom: 5px;
              max-width: 329px;

              @include mq('medium') {
                max-width: 100%;
              }

              @include mq('large') {
                margin-top: 10px;
                margin-bottom: 40px;
              }

              h2 {
                font-size: 60px;
                line-height: 60px;
                font-weight: 300;
                letter-spacing: -1.2px;
                margin-top: 37px;
                margin-bottom: 37px;

                @include mq('large') {
                  font-size: 97px;
                  line-height: 97px;
                  letter-spacing: -2.91px;
                  margin-top: 33px;
                  margin-bottom: 33px;
                }

                sup {
                  // fix: Too small `sup` text as per the UAT Feedback
                  // If still small, increase the percentage below
                  font-size: 61%;
                }
              }

              p {
                font-size: var(--emu-semantic-font-sizes-narrow-small);
                line-height: var(--emu-semantic-line-heights-narrow-small);
                font-weight: 400;
                letter-spacing: -0.48px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
                margin-bottom: 12px;

                @include mq('large') {
                  font-size: var(--emu-semantic-font-sizes-wide-small);
                  line-height: var(--emu-semantic-line-heights-wide-small);
                  letter-spacing: -0.72px;
                  margin-top: var(--emu-common-spacing-none);
                  margin-bottom: var(--emu-common-spacing-none);
                }

                br {
                  display: none;

                  @include mq('medium') {
                    display: block;
                  }
                }
              }
            }

            &-image-text-wrapper {
              display: flex;
              gap: 36px;
              align-items: baseline;
              margin-bottom: 42px;
              margin-top: var(--emu-common-spacing-xs);

              @include mq('large') {
                gap: 115px;
                margin-bottom: var(--emu-common-spacing-large);
                margin-top: var(--emu-common-spacing-none);
              }
            }

            &-image-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;

              @include mq('large') {
                gap: var(--emu-common-spacing-small);
              }

              img {
                width: 80px;
                height: auto;
                object-fit: cover;

                @include mq('large') {
                  width: 105px;
                }
              }
            }
          }
        }

        &--third {
          display: flex;
          flex-direction: column;
          align-items: center;
          bottom: 0; 
          top: auto;
          margin-bottom: 53px;

          @include mq('large') {
            align-items: end;
            top: 0;
            bottom: auto;
            margin-bottom: 0;
          }

          .home__banner-carousel {
            &-button-content {
              a {
                width: 228px;
                font-size: var(--emu-semantic-font-sizes-narrow-small);
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: 3.2px;
              }
            
              @include mq('large') {
                margin-right: 105px;

                a {
                  width: 285px;
                  font-size: 18px;
                  letter-spacing: 3.6px;
                }
              }
              @include mq('xx-large') {
                margin-right: 220px;
              }
            }
            
            &-text-content {
              margin-bottom: 5px;
              max-width: 360px;

              @include mq('large') {
                max-width: 480px;
                margin-top: 0px;
                margin-bottom: 40px;
                margin-right: 10px;
              }
              @include mq('xx-large') {
                max-width: 100%;
                margin-right: 60px;
              }

              h2 {
                font-size: 43px;
                font-style: normal;
                font-weight: 300;
                line-height: 90%;
                letter-spacing: -0.86px;

                @include mq('large') {
                  font-size: 60px;
                  line-height: 60px;
                  font-weight: 300;
                  letter-spacing: -1.2px;
                  margin-top: 37px;
                  margin-bottom: 37px;
                }
                @include mq('xx-large') {
                  font-size: 82px;
                  line-height: 100%;
                  letter-spacing: -2.46px;
                  margin-top: 33px;
                  margin-bottom: 33px;
                }

                sup {
                  // fix: Too small `sup` text as per the UAT Feedback
                  // If still small, increase the percentage below
                  font-size: 61%;
                }
              }

              p {
                font-size: var(--emu-semantic-font-sizes-narrow-small);
                line-height: var(--emu-semantic-line-heights-narrow-small);
                font-weight: 400;
                letter-spacing: -0.48px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;
                margin-bottom: 12px;

                @include mq('xx-large') {
                  font-size: var(--emu-semantic-font-sizes-wide-small);
                  line-height: var(--emu-semantic-line-heights-wide-small);
                  letter-spacing: -0.72px;
                  margin-top: var(--emu-common-spacing-none);
                  margin-bottom: 17px;
                }

                br {
                  display: none;

                  @include mq('medium') {
                    display: block;
                  }
                }

                b {
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 0.75px;

                  @include mq('large') {
                    font-size: 23px;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: 2.3px;
                  }
                }
              }
            }

            &-image-text-wrapper {
              display: flex;
              gap: 36px;
              align-items: baseline;
              margin-bottom: 42px;
              margin-top: var(--emu-common-spacing-xs);

              @include mq('large') {
                gap: 115px;
                margin-bottom: var(--emu-common-spacing-large);
                margin-top: var(--emu-common-spacing-none);
              }
            }

            &-image-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;

              @include mq('large') {
                gap: var(--emu-common-spacing-small);
              }

              img {
                width: 80px;
                height: auto;
                object-fit: cover;

                @include mq('large') {
                  width: 105px;
                }
              }
            }
          }
        }
      }

      &-teaser {
        width: 100%;
        height: 565px;

        @include mq('large') {
          height: 700px;
        }

        .aaaem-teaser__video {
          height: auto;

          @include mq('large') {
            height: 700px;
            object-fit: fill;
          }
        }

        .title-lockup {
          max-width: 100%;
        }

        .aaaem-teaser__title {
          text-align: center;
        }

        .teaser-video-controls {
          display: none;
        }

        img {
          width: 100%;
          height: 565px;
          object-fit: cover;

          @include mq('large') {
            height: 700px;
            object-fit: cover;
          }
        }

        .disclaimer {
          @include mq('large') {
            color: var(--emu-common-colors-white);
          }
        }
      }

      &-cta {
        width: 228px;

        @include mq('large') {
          width: 285px;
        }
      }

      .tns-ovh.tns-ah {
        height: 565px !important;

        @include mq('large') {
          height: 700px !important;
        }
      }
    }

    &__customized {
      display: flex;
      flex-direction: column;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 11px;
      padding-top: 20px;

      @include mq('large') {
        padding-top: 25px;
        padding-bottom: 20px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      &-text {
        margin-left: 15px;
        margin-right: 15px;

        @include mq('medium') {
          max-width: 640px;
          margin-left: auto;
          margin-right: auto;
        }

        @include mq('large') {
          max-width: 983px;
        }

        h2 {
          letter-spacing: -0.8px;
          margin-bottom: 25px;

          @include mq('medium') {
            br {
              display: none;
            }
          }

          @include mq('large') {
            margin-bottom: 13px;
            letter-spacing: -1.2px;
          }
        }

        p {
          letter-spacing: -0.48px;
          margin-bottom: 21px;

          @include mq('large') {
            letter-spacing: -0.72px;
          }
        }
      }

      &-cta {
        width: 228px;
        margin-bottom: 38px;

        @include mq('large') {
          width: 239px;
          margin-bottom: 29px;
        }
      }

      &-images-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 360px;

        @include mq('large') {
          gap: 26px;
          max-width: none;
        }
      }

      &-disclaimer-text {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: var(--emu-common-line-heights-narrow-medium);
        letter-spacing: -0.36px;
        margin-top: 10px;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-medium);
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.42px;
        }
      }
    }

    &__teaser-images {
      position: relative;

      .teaser {
        img {
          width: 100%;
          max-height: 745px; //to match design
          object-fit: cover;

          @include mq('large') {
            max-height: 843px;
          }
        }
      }

      &-content-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 27px;
        padding-left: 20px;
        padding-right: 20px;

        @include mq('large') {
          justify-content: center;
          gap: 55px;
        }

        &-title {
          margin-top: 39px;
          letter-spacing: -0.8px;
          max-width: 250px;
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            margin-top: 21px;
            letter-spacing: -1.2px;
            max-width: 100%;
          }
        }
      }

      &-image-text-containers-wrapper {
        display: flex;
        gap: 15px;
        justify-content: space-evenly;
        flex-wrap: wrap;

        @include mq('large') {
          justify-content: center;
          gap: 31px;
        }
      }

      &-image-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--emu-common-spacing-medium);
        width: 142px;

        @include mq('x-large') {
          gap: 28px;
          width: 100%;
          max-width: 193px;
        }

        img {
          max-width: 109px;

          @include mq('x-large') {
            max-width: 193px;
          }
        }

        p {
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: 1.2px;
          min-height: 41px;

          @include mq('large') {
            font-size: 15px;
            line-height: 22.5px;
            letter-spacing: 1.5px;
          }
        }

        &--width-sm {
          .cmp-text {
            @include mq('large') {
              max-width: 130px;
            }
          }
        }

        &--width-lg {
          width: 100%;
          max-width: 193px;
          margin-top: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);

            br {
              display: none;
            }
          }
        }
      }
    }

    &__awards {
      display: flex;
      flex-direction: column;
      padding-top: 45px;
      padding-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;
      gap: 14px;

      @include mq('large') {
        padding-top: 51px;
        padding-bottom: 21px;
        gap: var(--emu-common-spacing-none);
      }

      h2 {
        letter-spacing: -0.8px;

        @include mq('large') {
          line-height: 90px;
          letter-spacing: -1.2px;
        }
      }

      &-images {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-left: 26px;

        @include mq('large') {
          gap: 38px;
          margin-left: 12px;
        }

        &--first {
          max-width: 101px;

          @include mq('large') {
            max-width: 212px;
          }
        }

        &--second {
          max-width: 126px;

          @include mq('large') {
            max-width: 242px;
          }
        }

        &--third {
          max-width: 126px;

          @include mq('large') {
            max-width: 242px;
          }
        }
      }
    }
  }

  .video-text-container {
    &__wrapper {
      @include mq('large') {
        padding-left: 50px;
        padding-right: 50px;
      }

      @include mq('x-large') {
        padding-left: 63px;
        padding-right: 94px;
      }
    }

    &__text-content {
      max-width: 262px;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        max-width: 512px;
      }

      @include mq('large') {
        margin-bottom: 53px;
      }
    }
  }

  .dg {
    &-product-showcase {
      @include mq('large') {
        padding-top: 131px;
      }

      &__carousel {
        margin-left: 22px;

        @include mq('large') {
          margin-left: 65px;
        }
      }
    }

    &-fap__teaser {
      .aaaem-teaser {
        &__action-link {
          padding-right: 40px;
          padding-left: 40px;

          @include mq('large') {
            padding-right: 62px;
            padding-left: 62px;
          }
        }
      }
    }
  }
}
