.aaaem-accordion {
  border-bottom: var(
      --emu-component-lists-accordion-item-border-width-bottom-open
    )
    solid var(--emu-component-lists-accordion-color-background-dark);

  &__title {
    font-size: 25px;
    line-height: 34px;
    letter-spacing: -0.75px; // to match the design
    font-family: var(--emu-semantic-font-families-body);
    font-weight: var(--emu-common-font-weight-regular);

    @include mq('large') {
      font-size: 40px;
      line-height: var(--emu-semantic-line-heights-wide-xxl);
      letter-spacing: -1.2px;
    }
  }

  &__icon {
    margin-right: 14px;

    @include mq('large') {
      margin-right: var(--emu-common-spacing-none);
      height: 20px; // to match the design
      width: 20px; // to match the design
    }

    &::before,
    &::after {
      @include mq('large') {
        width: 4px; // to match the design
      }
    }
  }

  &__panel {
    .aaaem-text {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.6px; // to match the design

      @include mq('large') {
        font-size: 30px;
        line-height: 45px;
        letter-spacing: -0.9px;
      }
    }
  }

  &__header {
    border-bottom: none;
    border-top: var(
        --emu-component-lists-accordion-item-border-width-bottom-open
      )
      solid
      var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
  }

  dt:first-child {
    .aaaem-accordion__header {
      border-top: none;
    }
  }

  .js-badger-accordion-panel-inner {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: 30px;
  }
}
