footer {
  .cmp-experiencefragment--footer {
    max-width: var(--emu-component-containers-container-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  &.footer {
    padding-top: 45px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 12px;
    background-image: url('../../assets/images/footer-bg-mobile.png'); // to match the design.
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include mq('large') {
      background-image: url('../../assets/images/footer-bg-desktop.png');
      padding-top: 30px;
      padding-right: 15px;
      padding-bottom: 21px;
      padding-left: 15px;
    }

    @include mq('x-large') {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  .footer {
    .aaaem-text {
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: var(--emu-common-line-heights-narrow-medium);
      letter-spacing: -0.24px; // As per the design.

      p {
        font-weight: var(--emu-semantic-font-weight-600);
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    &__logo {
      .aaaem-image {
        max-width: 192px; // As per the design.
        margin-bottom: 35px;
      }
    }

    &__top-container {
      padding-bottom: var(--emu-common-spacing-none);

      @include mq('large') {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom-width: var(--emu-common-border-width-thin);
        border-bottom-color: #48628429; // Used only in this place.
        border-bottom-style: solid;
        margin-bottom: 6px;
      }
    }

    &__image {
      @include mq('large') {
        max-width: 192px;
        margin-top: 26px;
        margin-bottom: 82px;
      }

      @include mq('x-large') {
        max-width: none;
      }

      &-section {
        display: none;

        @include mq('large') {
          display: block;
        }
      }
    }

    &__social {
      &-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 23px;
        margin-bottom: 20px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
          justify-content: flex-start;
        }
      }
    }

    &__nav {
      &-container {
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          display: flex;
          flex-direction: row;
          gap: 35px;
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('1140px') {
          gap: 50px;
        }

        .aaaem-text {
          p {
            font-size: var(--emu-semantic-font-sizes-narrow-small);
            line-height: var(--emu-semantic-line-heights-wide-large);
            margin-bottom: var(--emu-common-spacing-medium);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: var(--emu-semantic-line-heights-wide-large);
              letter-spacing: -0.4px; // As per the design.
              margin-bottom: 24px;
            }
          }

          ul {
            margin: var(--emu-common-spacing-none);
            padding: var(--emu-common-spacing-none);

            li {
              font-size: 13px;
              line-height: var(--emu-common-line-heights-narrow-large);
              list-style: none;
              margin-bottom: var(--emu-common-spacing-medium);
              font-weight: var(--emu-semantic-font-weight-500);

              @include mq('large') {
                font-size: var(--emu-common-font-sizes-wide-medium);
                line-height: var(--emu-common-line-heights-wide-medium);
                letter-spacing: -0.32px; // As per the design.
                margin-bottom: 23px;
              }
            }
          }
        }
      }

      &-links {
        border-bottom-width: var(--emu-common-border-width-thin);
        border-bottom-color: #000; // Used only in this place.
        border-bottom-style: solid;
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          border: var(--emu-common-border-width-none);
          margin: var(--emu-common-spacing-none);
        }

        li {
          .emphasis {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              max-width: 31px; // to match the design.
              display: inline-block;

              @include mq('large') {
                max-width: 50px;
              }
            }
          }
        }
      }
    }

    &__bottom {
      &-container {
        .footer__social-container {
          @include mq('large') {
            display: none;
          }
        }
      }

      &-text.aaaem-text {
        text-align: center;

        @include mq('large') {
          text-align: left;
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-medium);
          letter-spacing: -0.32px; // to match the design.
          margin-top: 15px;
        }

        p {
          font-weight: var(--emu-semantic-font-weight-500);
        }
      }
    }
  }

  &__img-link {
    grid-gap: 10px;
    align-items: center;
    gap: 10px;
    display: flex;

    img {
      max-width: 40px;
      display: inline-block;
    }
  }
}
