@import "https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

sup {
  vertical-align: super;
  top: var(--emu-common-spacing-none);
  font-size: 50%;
  line-height: 0;
  position: relative;
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Roboto, Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-secondary-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-50: #a57f74;
  --emu-semantic-colors-primary-100: #8a5e56;
  --emu-semantic-colors-primary-200: #442929;
  --emu-semantic-colors-primary-300: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-primary-400: var(--emu-common-colors-medium-brown-400);
  --emu-semantic-colors-primary-500: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-primary-600: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-primary-700: var(--emu-common-colors-medium-brown-700);
  --emu-semantic-colors-primary-800: var(--emu-common-colors-medium-brown-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-medium-brown-900);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: #fdfbf8;
  --emu-semantic-colors-secondary-100: #f3eeed;
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-blush-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-blush-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: #486284;
  --emu-semantic-colors-tertiary-100: #1a202c;
  --emu-semantic-colors-tertiary-200: #231f20;
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-500: 500;
  --emu-semantic-font-weight-600: 600;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "Roboto", sans-serif;
  --emu-semantic-font-families-heading: "Noto Serif Display", sans-serif;
  --emu-semantic-font-families-mono: "Noto Serif", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: var(--emu-semantic-spacing-one-line-height-wide) * 2px;
  --emu-semantic-spacing-two-line-height-narrow: var(--emu-semantic-spacing-one-line-height-narrow) * 2px;
  --emu-semantic-spacing-three-line-height-wide: var(--emu-semantic-spacing-one-line-height-wide) * 3px;
  --emu-semantic-spacing-three-line-height-narrow: var(--emu-semantic-spacing-one-line-height-narrow) * 3px;
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-onerem-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-tworem-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-tworem-wide: var(--emu-common-spacing-none) px;
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-wide: var(--emu-common-spacing-none);
  --emu-semantic-spacing-half-line-height-wide: var(--emu-semantic-line-heights-wide-small) * .5px;
  --emu-semantic-spacing-half-line-height-narrow: var(--emu-semantic-line-heights-narrow-small) * .5px;
  --emu-semantic-spacing-quarter-line-height-wide: var(--emu-semantic-line-heights-wide-small) * .25px;
  --emu-semantic-spacing-quarter-line-height-narrow: var(--emu-semantic-line-heights-narrow-small) * .25px;
  --emu-semantic-font-sizes-narrow-medium: 15px;
  --emu-semantic-font-sizes-narrow-large: 18px;
  --emu-semantic-font-sizes-narrow-xl: 40px;
  --emu-semantic-font-sizes-narrow-xxl: 40px;
  --emu-semantic-font-sizes-narrow-xxxl: 48.83px;
  --emu-semantic-font-sizes-narrow-small: 16px;
  --emu-semantic-font-sizes-narrow-xs: 12px;
  --emu-semantic-font-sizes-wide-medium: 19px;
  --emu-semantic-font-sizes-wide-large: 20px;
  --emu-semantic-font-sizes-wide-xl: 43px;
  --emu-semantic-font-sizes-wide-xxl: 60px;
  --emu-semantic-font-sizes-wide-xxxl: 54.93px;
  --emu-semantic-font-sizes-wide-small: 24px;
  --emu-semantic-font-sizes-wide-xs: 12px;
  --emu-semantic-line-heights-narrow-medium: 22.5px;
  --emu-semantic-line-heights-narrow-large: 27px;
  --emu-semantic-line-heights-narrow-xl: 50px;
  --emu-semantic-line-heights-narrow-xxl: 40px;
  --emu-semantic-line-heights-narrow-xxxl: var(--emu-semantic-line-heights-narrow-small) * 2.5px;
  --emu-semantic-line-heights-narrow-small: 24px;
  --emu-semantic-line-heights-narrow-xs: 15px;
  --emu-semantic-line-heights-wide-medium: 28.5px;
  --emu-semantic-line-heights-wide-large: 30px;
  --emu-semantic-line-heights-wide-xl: 64.5px;
  --emu-semantic-line-heights-wide-xxl: 60px;
  --emu-semantic-line-heights-wide-xxxl: var(--emu-semantic-line-heights-wide-small) * 3px;
  --emu-semantic-line-heights-wide-small: 36px;
  --emu-semantic-line-heights-wide-xs: 15px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-tablet: 992px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: var(--emu-semantic-sizing-one-line-height-narrow) * 2px;
  --emu-semantic-sizing-two-line-height-wide: var(--emu-semantic-sizing-one-line-height-wide) * 2px;
  --emu-semantic-sizing-three-line-height-narrow: var(--emu-semantic-sizing-one-line-height-narrow) * 3px;
  --emu-semantic-sizing-three-line-height-wide: var(--emu-semantic-sizing-one-line-height-wide) * 3px;
  --emu-semantic-sizing-max-width-text-narrow: 40empx;
  --emu-semantic-sizing-max-width-text-wide: 55empx;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-line-heights-narrow-xxl) / var(--emu-semantic-font-sizes-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-line-heights-wide-xxl) / var(--emu-semantic-font-sizes-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 884px;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-component-lists-accordion-margin-top-narrow) var(--emu-component-lists-accordion-margin-right-narrow) var(--emu-component-lists-accordion-margin-bottom-narrow) var(--emu-component-lists-accordion-margin-left-narrow);
  --emu-component-lists-accordion-margin-wide: var(--emu-component-lists-accordion-margin-top-wide) var(--emu-component-lists-accordion-margin-right-wide) var(--emu-component-lists-accordion-margin-bottom-wide) var(--emu-component-lists-accordion-margin-left-wide);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-component-lists-accordion-item-body-padding-bottom-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: 30px;
  --emu-component-lists-accordion-item-body-padding-top-wide: 30px;
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: 30px;
  --emu-component-lists-accordion-item-header-padding-top-wide: 30px;
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: 30px;
  --emu-component-lists-accordion-item-header-padding-bottom-wide: 30px;
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-common-colors-black);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 12px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) * .5px;
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow)  - 4px;
  --emu-component-ingredients-radio-input-icon-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide)  - 4px;
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow)  - 4px;
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide)  - 4px;
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-bold) 18px / 27px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-none);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-primary-50);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: var(--emu-semantic-sizing-one-line-height-narrow) * .75px;
  --emu-component-actions-button-icon-size-height-wide: var(--emu-semantic-sizing-one-line-height-wide) * .75px;
  --emu-component-actions-button-icon-size-width-narrow: var(--emu-semantic-sizing-one-line-height-narrow) * .75px;
  --emu-component-actions-button-icon-size-width-wide: var(--emu-semantic-sizing-one-line-height-wide) * .75px;
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: 13px;
  --emu-component-actions-button-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-bottom-narrow: 13px;
  --emu-component-actions-button-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 12px;
  --emu-component-containers-isi-header-padding-top-wide: 9px;
  --emu-component-containers-isi-header-padding-right-narrow: 12px;
  --emu-component-containers-isi-header-padding-right-wide: 42px;
  --emu-component-containers-isi-header-padding-bottom-narrow: 12px;
  --emu-component-containers-isi-header-padding-bottom-wide: 9px;
  --emu-component-containers-isi-header-padding-left-narrow: 14px;
  --emu-component-containers-isi-header-padding-left-wide: 21px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-common-colors-black);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: 27px;
  --emu-component-containers-isi-body-padding-top-wide: 40px;
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-right-wide: 60px;
  --emu-component-containers-isi-body-padding-bottom-narrow: 59px;
  --emu-component-containers-isi-body-padding-bottom-wide: 58px;
  --emu-component-containers-isi-body-padding-left-narrow: 31px;
  --emu-component-containers-isi-body-padding-left-wide: 46px;
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 1800px;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-gap-horizontal-narrow: 33px;
  --emu-component-containers-carousel-gap-horizontal-wide: 39px;
  --emu-component-containers-carousel-gap-vertical-narrow: 19px;
  --emu-component-containers-carousel-gap-vertical-wide: 65px;
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: 12px;
  --emu-component-containers-carousel-controls-padding-top-wide: 10px;
  --emu-component-containers-carousel-controls-padding-right-narrow: 12px;
  --emu-component-containers-carousel-controls-padding-right-wide: 10px;
  --emu-component-containers-carousel-controls-padding-bottom-narrow: 12px;
  --emu-component-containers-carousel-controls-padding-bottom-wide: 10px;
  --emu-component-containers-carousel-controls-padding-left-narrow: 12px;
  --emu-component-containers-carousel-controls-padding-left-wide: 10px;
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

.content--centered {
  text-align: center;
}

.container--root {
  margin-top: 81px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .container--root {
    margin-top: 140px;
  }
}

[data-component="title"].text--color-brown-50, [data-component="text"].text--color-brown-50 {
  color: var(--emu-semantic-colors-primary-50);
}

.bg--light-grey-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.bg--light-grey-100 {
  background-color: var(--emu-semantic-colors-secondary-100);
}

.animated-section {
  opacity: 0;
  transition: none;
}

.cq-Editable-dom--container .animated-section {
  opacity: 1 !important;
  transition: none !important;
  transform: none !important;
}

.animated-section--animated {
  opacity: 1;
  transition: all var(--emu-common-other-time-transition-base) linear;
}

.animated-section--scale {
  transform: scale(0);
}

.animated-section--scale.animated-section--animated {
  transform: scale(1);
}

.animated-section--fade-in-up {
  opacity: 0;
  transform: translateY(50px);
}

.animated-section--fade-in-up.animated-section--animated {
  opacity: 1;
  transform: translateY(0);
}

.duration--100ms {
  transition-duration: .1s !important;
  animation-duration: .1s !important;
}

.duration--200ms {
  transition-duration: .2s !important;
  animation-duration: .2s !important;
}

.duration--300ms {
  transition-duration: .3s !important;
  animation-duration: .3s !important;
}

.duration--400ms {
  transition-duration: .4s !important;
  animation-duration: .4s !important;
}

.duration--500ms {
  transition-duration: .5s !important;
  animation-duration: .5s !important;
}

.duration--600ms {
  transition-duration: .6s !important;
  animation-duration: .6s !important;
}

.duration--700ms {
  transition-duration: .7s !important;
  animation-duration: .7s !important;
}

.duration--800ms {
  transition-duration: .8s !important;
  animation-duration: .8s !important;
}

.duration--900ms {
  transition-duration: .9s !important;
  animation-duration: .9s !important;
}

.duration--1000ms {
  transition-duration: 1s !important;
  animation-duration: 1s !important;
}

.delay--100ms {
  transition-delay: .1s !important;
  animation-delay: .1s !important;
}

.delay--200ms {
  transition-delay: .2s !important;
  animation-delay: .2s !important;
}

.delay--300ms {
  transition-delay: .3s !important;
  animation-delay: .3s !important;
}

.delay--400ms {
  transition-delay: .4s !important;
  animation-delay: .4s !important;
}

.delay--500ms {
  transition-delay: .5s !important;
  animation-delay: .5s !important;
}

.delay--600ms {
  transition-delay: .6s !important;
  animation-delay: .6s !important;
}

.delay--700ms {
  transition-delay: .7s !important;
  animation-delay: .7s !important;
}

.delay--800ms {
  transition-delay: .8s !important;
  animation-delay: .8s !important;
}

.delay--900ms {
  transition-delay: .9s !important;
  animation-delay: .9s !important;
}

.delay--1000ms {
  transition-delay: 1s !important;
  animation-delay: 1s !important;
}

.aspect-16-11 {
  aspect-ratio: 16 / 11;
  overflow: hidden;
}

.aspect-16-11 img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.consumer-page .isi--hcp, .hcp-page .isi--consumer {
  display: none;
}

.main-banner-teaser {
  aspect-ratio: 131 / 160;
  min-height: 170px;
}

@media (min-width: 1024px) {
  .main-banner-teaser {
    aspect-ratio: 144 / 55;
    min-height: 280px;
  }
}

#home .home__banner-carousel {
  margin-bottom: 22px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel {
    margin-bottom: 43px;
  }
}

#home .home__banner-carousel .aaaem-carousel {
  margin: var(--emu-common-spacing-none);
}

#home .home__banner-carousel .aaaem-carousel__actions {
  display: none;
}

#home .home__banner-carousel .aaaem-carousel__indicators {
  grid-gap: 5px;
  gap: 5px;
  position: relative;
  bottom: 25px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel .aaaem-carousel__indicators {
    grid-gap: 10px;
    gap: 10px;
    bottom: 50px;
  }
}

#home .home__banner-carousel .aaaem-carousel__indicator {
  background-color: var(--emu-common-colors-white);
  opacity: .5;
  width: 5px;
  height: 5px;
}

#home .home__banner-carousel .aaaem-carousel__indicator.tns-nav-active {
  opacity: 1;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel .aaaem-carousel__indicator {
    width: 12px;
    height: 12px;
  }
}

#home .home__banner-carousel .aaaem-carousel__item-container {
  position: relative;
}

#home .home__banner-carousel-content-wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 0;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper {
    height: 100%;
  }
}

@media (min-width: 1280px) {
  #home .home__banner-carousel-content-wrapper {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home .home__banner-carousel-content-wrapper--first {
  grid-gap: var(--emu-common-spacing-large);
  gap: var(--emu-common-spacing-large);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--first {
    grid-gap: 120px;
    flex-direction: row;
    gap: 120px;
  }
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content {
  grid-gap: 26px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  margin-top: 11px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content {
    grid-gap: var(--emu-common-spacing-large);
    gap: var(--emu-common-spacing-large);
    margin-top: 36px;
  }
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content h2 {
  font-size: 23px;
  line-height: 20px;
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: -.46px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content h2 {
    letter-spacing: -.74px;
    font-size: 37px;
    line-height: 60px;
  }
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content h2 .emphasis {
  letter-spacing: -1.4px;
  font-size: 70px;
  line-height: 84px;
}

@media (min-width: 1280px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-text-content h2 .emphasis {
    letter-spacing: -2.96px;
    align-items: center;
    max-height: 150px;
    font-size: 148px;
    line-height: 178px;
    display: flex;
  }
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-content {
  max-width: 337px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-content {
    max-width: 599px;
    margin-top: 30px;
  }
}

@media (min-width: 1350px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-content {
    transform: translateX(32px);
  }
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-content img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

#home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-disclaimer {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-top: var(--emu-common-spacing-small);
  letter-spacing: -.36px;
  line-height: 18px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--first .home__banner-carousel-image-disclaimer {
    letter-spacing: -.42px;
    font-size: 14px;
    line-height: 21px;
  }
}

#home .home__banner-carousel-content-wrapper--second {
  flex-direction: column;
  display: flex;
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content {
  max-width: 329px;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content h2 {
  letter-spacing: -1.2px;
  margin-top: 37px;
  margin-bottom: 37px;
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content h2 {
    letter-spacing: -2.91px;
    margin-top: 33px;
    margin-bottom: 33px;
    font-size: 97px;
    line-height: 97px;
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content h2 sup {
  font-size: 61%;
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  letter-spacing: -.48px;
  margin: 12px auto;
  font-weight: 400;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content p {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    letter-spacing: -.72px;
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content p br {
  display: none;
}

@media (min-width: 768px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-text-content p br {
    display: block;
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text-wrapper {
  grid-gap: 36px;
  margin-bottom: 42px;
  margin-top: var(--emu-common-spacing-xs);
  align-items: baseline;
  gap: 36px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text-wrapper {
    grid-gap: 115px;
    margin-bottom: var(--emu-common-spacing-large);
    margin-top: var(--emu-common-spacing-none);
    gap: 115px;
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text {
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text {
    grid-gap: var(--emu-common-spacing-small);
    gap: var(--emu-common-spacing-small);
  }
}

#home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 80px;
  height: auto;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--second .home__banner-carousel-image-text img {
    width: 105px;
  }
}

#home .home__banner-carousel-content-wrapper--third {
  flex-direction: column;
  align-items: center;
  margin-bottom: 53px;
  display: flex;
  top: auto;
  bottom: 0;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third {
    align-items: end;
    margin-bottom: 0;
    top: 0;
    bottom: auto;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-button-content a {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: 3.2px;
  width: 228px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-button-content {
    margin-right: 105px;
  }

  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-button-content a {
    letter-spacing: 3.6px;
    width: 285px;
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-button-content {
    margin-right: 220px;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content {
  max-width: 360px;
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content {
    max-width: 480px;
    margin-top: 0;
    margin-bottom: 40px;
    margin-right: 10px;
  }
}

@media (min-width: 1440px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content {
    max-width: 100%;
    margin-right: 60px;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content h2 {
  letter-spacing: -.86px;
  font-size: 43px;
  font-style: normal;
  font-weight: 300;
  line-height: 90%;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content h2 {
    letter-spacing: -1.2px;
    margin-top: 37px;
    margin-bottom: 37px;
    font-size: 60px;
    font-weight: 300;
    line-height: 60px;
  }
}

@media (min-width: 1440px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content h2 {
    letter-spacing: -2.46px;
    margin-top: 33px;
    margin-bottom: 33px;
    font-size: 82px;
    line-height: 100%;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content h2 sup {
  font-size: 61%;
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  letter-spacing: -.48px;
  margin: 12px auto;
  font-weight: 400;
}

@media (min-width: 1440px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    letter-spacing: -.72px;
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 17px;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p br {
  display: none;
}

@media (min-width: 768px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p br {
    display: block;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p b {
  letter-spacing: .75px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-text-content p b {
    letter-spacing: 2.3px;
    font-size: 23px;
    font-weight: 600;
    line-height: 150%;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text-wrapper {
  grid-gap: 36px;
  margin-bottom: 42px;
  margin-top: var(--emu-common-spacing-xs);
  align-items: baseline;
  gap: 36px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text-wrapper {
    grid-gap: 115px;
    margin-bottom: var(--emu-common-spacing-large);
    margin-top: var(--emu-common-spacing-none);
    gap: 115px;
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text {
  grid-gap: 5px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text {
    grid-gap: var(--emu-common-spacing-small);
    gap: var(--emu-common-spacing-small);
  }
}

#home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 80px;
  height: auto;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-content-wrapper--third .home__banner-carousel-image-text img {
    width: 105px;
  }
}

#home .home__banner-carousel-teaser {
  width: 100%;
  height: 565px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-teaser {
    height: 700px;
  }
}

#home .home__banner-carousel-teaser .aaaem-teaser__video {
  height: auto;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-teaser .aaaem-teaser__video {
    -o-object-fit: fill;
    object-fit: fill;
    height: 700px;
  }
}

#home .home__banner-carousel-teaser .title-lockup {
  max-width: 100%;
}

#home .home__banner-carousel-teaser .aaaem-teaser__title {
  text-align: center;
}

#home .home__banner-carousel-teaser .teaser-video-controls {
  display: none;
}

#home .home__banner-carousel-teaser img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 565px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-teaser img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 700px;
  }

  #home .home__banner-carousel-teaser .disclaimer {
    color: var(--emu-common-colors-white);
  }
}

#home .home__banner-carousel-cta {
  width: 228px;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel-cta {
    width: 285px;
  }
}

#home .home__banner-carousel .tns-ovh.tns-ah {
  height: 565px !important;
}

@media (min-width: 1024px) {
  #home .home__banner-carousel .tns-ovh.tns-ah {
    height: 700px !important;
  }
}

#home .home__customized {
  flex-direction: column;
  padding: 20px 18px 11px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__customized {
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home .home__customized-text {
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 768px) {
  #home .home__customized-text {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  #home .home__customized-text {
    max-width: 983px;
  }
}

#home .home__customized-text h2 {
  letter-spacing: -.8px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #home .home__customized-text h2 br {
    display: none;
  }
}

@media (min-width: 1024px) {
  #home .home__customized-text h2 {
    letter-spacing: -1.2px;
    margin-bottom: 13px;
  }
}

#home .home__customized-text p {
  letter-spacing: -.48px;
  margin-bottom: 21px;
}

@media (min-width: 1024px) {
  #home .home__customized-text p {
    letter-spacing: -.72px;
  }
}

#home .home__customized-cta {
  width: 228px;
  margin-bottom: 38px;
}

@media (min-width: 1024px) {
  #home .home__customized-cta {
    width: 239px;
    margin-bottom: 29px;
  }
}

#home .home__customized-images-container {
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__customized-images-container {
    grid-gap: 26px;
    gap: 26px;
    max-width: none;
  }
}

#home .home__customized-disclaimer-text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: -.36px;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  #home .home__customized-disclaimer-text {
    margin-top: var(--emu-common-spacing-medium);
    letter-spacing: -.42px;
    font-size: 14px;
    line-height: 21px;
  }
}

#home .home__teaser-images {
  position: relative;
}

#home .home__teaser-images .teaser img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 745px;
}

@media (min-width: 1024px) {
  #home .home__teaser-images .teaser img {
    max-height: 843px;
  }
}

#home .home__teaser-images-content-wrapper {
  grid-gap: 27px;
  flex-direction: column;
  gap: 27px;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: absolute;
  top: 0;
}

@media (min-width: 1024px) {
  #home .home__teaser-images-content-wrapper {
    grid-gap: 55px;
    justify-content: center;
    gap: 55px;
  }
}

#home .home__teaser-images-content-wrapper-title {
  letter-spacing: -.8px;
  max-width: 250px;
  margin-top: 39px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #home .home__teaser-images-content-wrapper-title {
    letter-spacing: -1.2px;
    max-width: 100%;
    margin-top: 21px;
  }
}

#home .home__teaser-images-image-text-containers-wrapper {
  grid-gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__teaser-images-image-text-containers-wrapper {
    grid-gap: 31px;
    justify-content: center;
    gap: 31px;
  }
}

#home .home__teaser-images-image-text-container {
  grid-gap: var(--emu-common-spacing-medium);
  align-items: center;
  gap: var(--emu-common-spacing-medium);
  flex-direction: column;
  width: 142px;
  display: flex;
}

@media (min-width: 1280px) {
  #home .home__teaser-images-image-text-container {
    grid-gap: 28px;
    gap: 28px;
    width: 100%;
    max-width: 193px;
  }
}

#home .home__teaser-images-image-text-container img {
  max-width: 109px;
}

@media (min-width: 1280px) {
  #home .home__teaser-images-image-text-container img {
    max-width: 193px;
  }
}

#home .home__teaser-images-image-text-container p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-large);
  letter-spacing: 1.2px;
  min-height: 41px;
}

@media (min-width: 1024px) {
  #home .home__teaser-images-image-text-container p {
    letter-spacing: 1.5px;
    font-size: 15px;
    line-height: 22.5px;
  }

  #home .home__teaser-images-image-text-container--width-sm .cmp-text {
    max-width: 130px;
  }
}

#home .home__teaser-images-image-text-container--width-lg {
  margin-top: var(--emu-common-spacing-small);
  width: 100%;
  max-width: 193px;
}

@media (min-width: 1024px) {
  #home .home__teaser-images-image-text-container--width-lg {
    margin-top: var(--emu-common-spacing-none);
  }

  #home .home__teaser-images-image-text-container--width-lg br {
    display: none;
  }
}

#home .home__awards {
  grid-gap: 14px;
  flex-direction: column;
  gap: 14px;
  padding: 45px 20px 24px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__awards {
    grid-gap: var(--emu-common-spacing-none);
    gap: var(--emu-common-spacing-none);
    padding-top: 51px;
    padding-bottom: 21px;
  }
}

#home .home__awards h2 {
  letter-spacing: -.8px;
}

@media (min-width: 1024px) {
  #home .home__awards h2 {
    letter-spacing: -1.2px;
    line-height: 90px;
  }
}

#home .home__awards-images {
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 26px;
  display: flex;
}

@media (min-width: 1024px) {
  #home .home__awards-images {
    grid-gap: 38px;
    gap: 38px;
    margin-left: 12px;
  }
}

#home .home__awards-images--first {
  max-width: 101px;
}

@media (min-width: 1024px) {
  #home .home__awards-images--first {
    max-width: 212px;
  }
}

#home .home__awards-images--second {
  max-width: 126px;
}

@media (min-width: 1024px) {
  #home .home__awards-images--second {
    max-width: 242px;
  }
}

#home .home__awards-images--third {
  max-width: 126px;
}

@media (min-width: 1024px) {
  #home .home__awards-images--third {
    max-width: 242px;
  }

  #home .video-text-container__wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 1280px) {
  #home .video-text-container__wrapper {
    padding-left: 63px;
    padding-right: 94px;
  }
}

#home .video-text-container__text-content {
  max-width: 262px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #home .video-text-container__text-content {
    max-width: 512px;
  }
}

@media (min-width: 1024px) {
  #home .video-text-container__text-content {
    margin-bottom: 53px;
  }

  #home .dg-product-showcase {
    padding-top: 131px;
  }
}

#home .dg-product-showcase__carousel {
  margin-left: 22px;
}

@media (min-width: 1024px) {
  #home .dg-product-showcase__carousel {
    margin-left: 65px;
  }
}

#home .dg-fap__teaser .aaaem-teaser__action-link {
  padding-left: 40px;
  padding-right: 40px;
}

@media (min-width: 1024px) {
  #home .dg-fap__teaser .aaaem-teaser__action-link {
    padding-left: 62px;
    padding-right: 62px;
  }
}

#customized .customized-teaser-container {
  padding-top: 33px;
  padding-bottom: 31px;
}

@media (min-width: 1024px) {
  #customized .customized-teaser-container {
    padding-top: 54px;
    padding-bottom: 54px;
  }
}

#customized .customized-teaser-container .aaaem-teaser__subtitle {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  font-weight: var(--emu-common-font-weight-bold);
  letter-spacing: .54px;
  margin-top: 14px;
  margin-bottom: 14px;
}

@media (min-width: 1024px) {
  #customized .customized-teaser-container .aaaem-teaser__subtitle {
    letter-spacing: 2.1px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 21px;
    line-height: 31.5px;
  }
}

#customized .customized-teaser-container .cmp-teaser__description {
  font-size: var(--emu-semantic-font-sizes-wide-small);
  line-height: var(--emu-common-line-heights-narrow-xl);
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: .03px;
  max-width: 68%;
}

@media (min-width: 1024px) {
  #customized .customized-teaser-container .cmp-teaser__description {
    max-width: 70%;
  }
}

@media (min-width: 1280px) {
  #customized .customized-teaser-container .cmp-teaser__description {
    max-width: 44%;
  }
}

#customized .customized-teaser-container .cmp-teaser__description p {
  letter-spacing: -.48px;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  #customized .customized-teaser-container .cmp-teaser__description p {
    letter-spacing: -.72px;
    margin-bottom: 36px;
  }
}

#customized .customized-teaser-container .cmp-teaser__description p:last-child {
  margin-bottom: var(--emu-common-sizing-none);
}

#customized .customized-teaser-container .aaaem-image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 478px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1024px) {
  #customized .customized-teaser-container .aaaem-image img {
    padding-left: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-xl);
    max-height: 100%;
  }
}

#customized .dg-product-showcase {
  padding-bottom: 29px;
}

@media (min-width: 1024px) {
  #customized .dg-product-showcase {
    padding-bottom: var(--emu-common-spacing-small);
  }
}

#customized .dg-product-showcase__subtitle .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: .54px;
}

@media (min-width: 1024px) {
  #customized .dg-product-showcase__subtitle .cmp-title__text {
    letter-spacing: 2.1px;
    font-size: 21px;
    line-height: 31.5px;
  }

  #customized .dg-product-showcase__text[data-component="text"] {
    max-width: 750px;
  }
}

#customized .dg-product-showcase__carousel {
  margin-top: 44px;
}

@media (min-width: 1024px) {
  #customized .dg-product-showcase__carousel {
    margin-top: 65px;
  }
}

#customized .dg-product-showcase__carousel .aaaem-image img {
  width: 100%;
}

#customized .dg-product-showcase .aaaem-carousel__actions {
  right: 31px;
}

@media (min-width: 1024px) {
  #customized .dg-product-showcase .aaaem-carousel__actions {
    right: auto;
    right: initial;
  }
}

#customized .dg-product-showcase .product-card__text {
  margin-bottom: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  #customized .dg-product-showcase .product-card__text {
    margin-bottom: 20px;
  }
}

#customized .gallery-redirector__wrapper {
  margin-bottom: var(--emu-common-spacing-none);
  padding-bottom: 42px;
}

@media (min-width: 1024px) {
  #customized .gallery-redirector__wrapper {
    padding-bottom: 51px;
  }

  #customized .gallery-redirector__text-content {
    width: 426px;
  }
}

#customized .gallery-redirector__cta {
  min-width: 228px;
}

@media (min-width: 1024px) {
  #customized .gallery-redirector__cta {
    min-width: 285px;
  }
}

#customized .gallery-redirector__disclaimer-text {
  letter-spacing: -.36px;
  max-width: 320px;
}

@media (min-width: 1024px) {
  #customized .gallery-redirector__disclaimer-text {
    letter-spacing: normal;
    max-width: 582px;
  }

  #customized .gallery-redirector__disclaimer-text p {
    padding-bottom: 10px;
  }
}

#customized .dg-testimonial__container {
  background-color: var(--emu-semantic-colors-secondary-50);
}

#error .error__page {
  margin: 80px auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1024px) {
  #error .error__page {
    padding: var(--emu-common-spacing-none);
    width: 60%;
  }
}

#error .error__title .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: 1.25;
  font-weight: var(--emu-common-font-weight-light);
  margin-bottom: 80px;
}

@media (min-width: 1024px) {
  #error .error__title .cmp-title__text {
    font-size: 55px;
  }
}

#faq .faq__accordion-container {
  background-color: var(--emu-semantic-colors-secondary-50);
  padding: 35px 24px 63px;
}

@media (min-width: 1024px) {
  #faq .faq__accordion-container {
    padding-top: 114px;
    padding-right: var(--emu-common-spacing-xl);
    padding-bottom: 114px;
    padding-left: var(--emu-common-spacing-xl);
  }

  #faq .faq__accordion-container .aaaem-accordion__panel {
    max-width: 80%;
  }
}

#faq .faq__accordion-container .aaaem-accordion__panel .aaaem-text .emphasis {
  display: inline-block;
}

#faq .faq__title .cmp-title__text {
  letter-spacing: -1.65px;
  margin-bottom: 19px;
  font-size: 55px;
  line-height: 55px;
}

@media (min-width: 1024px) {
  #faq .faq__title .cmp-title__text {
    letter-spacing: -1.98px;
    margin-bottom: 8px;
    font-size: 66px;
    line-height: 66px;
  }
}

#faq .faq__text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: -.54px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  #faq .faq__text {
    letter-spacing: -.87px;
    margin-bottom: var(--emu-common-spacing-large);
    font-size: 29px;
    line-height: 43.5px;
  }
}

#for-professionals .cmp-teaser__title {
  padding-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 1024px) {
  #for-professionals .cmp-teaser__title {
    margin-top: -20px;
  }
}

#for-professionals .video-text-container__wrapper {
  padding-top: 112px;
}

@media (min-width: 1024px) {
  #for-professionals .video-text-container__wrapper {
    grid-gap: 124px;
    justify-content: flex-start;
    gap: 124px;
    padding-top: 62px;
    padding-bottom: 51px;
    padding-left: 63px;
  }
}

#for-professionals .video-text-container__text-content {
  max-width: 338px;
}

@media (min-width: 1024px) {
  #for-professionals .video-text-container__text-content {
    margin-bottom: var(--emu-common-spacing-small);
    width: 530px;
    max-width: 100%;
  }
}

#for-professionals .video-text-container__text-content h2 {
  margin-bottom: 35px;
}

@media (min-width: 1024px) {
  #for-professionals .video-text-container__text-content h2 {
    max-width: 497px;
    margin-bottom: 56px;
    margin-left: auto;
    margin-right: auto;
  }
}

#for-professionals .video-text-container__text-content p {
  letter-spacing: -.48px;
}

@media (min-width: 1024px) {
  #for-professionals .video-text-container__text-content p {
    letter-spacing: normal;
  }
}

#for-professionals .video-text-container__video-content {
  grid-gap: 13px;
  gap: 13px;
}

#for-professionals .for-professionals-brandbox {
  background-color: var(--emu-semantic-colors-primary-100);
  grid-gap: 46px;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox {
    grid-gap: 109px;
    flex-direction: row;
    gap: 109px;
    padding: 37px 88px 45px 109px;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-brandbox {
    justify-content: center;
    align-items: normal;
  }

  #for-professionals .for-professionals-brandbox__content {
    margin-top: 83px;
  }
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox > .container:last-child {
    flex: 1;
    max-width: 558px;
  }
}

#for-professionals .for-professionals-brandbox__logo--mobile {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__logo--mobile {
    display: none;
  }
}

#for-professionals .for-professionals-brandbox__logo--desktop {
  display: none;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__logo--desktop {
    justify-content: center;
    display: flex;
  }
}

#for-professionals .for-professionals-brandbox__text {
  max-width: 301px;
  margin: 19px auto 31px;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__text {
    max-width: 369px;
    margin-top: -7px;
    margin-bottom: 40px;
  }
}

#for-professionals .for-professionals-brandbox__text p {
  letter-spacing: -.48px;
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__text p {
    letter-spacing: -.72px;
  }
}

#for-professionals .for-professionals-brandbox__cta {
  background-color: var(--emu-semantic-colors-primary-200);
  color: var(--emu-common-colors-white);
  border: none;
  padding: 18px 68px;
}

#for-professionals .for-professionals-brandbox__cta:hover, #for-professionals .for-professionals-brandbox__cta:active {
  color: var(--emu-common-colors-white);
  background-color: var(--emu-semantic-colors-primary-50);
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__cta {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: 17px;
    padding-left: 56px;
    padding-right: 56px;
  }
}

#for-professionals .for-professionals-brandbox__teaser-wrapper .aaaem-teaser .teaser-image-container {
  max-width: 342px;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-brandbox__teaser-wrapper .aaaem-teaser .teaser-image-container {
    max-width: 558px;
  }
}

#for-professionals .for-professionals-discover-alle {
  padding-top: 27px;
  padding-bottom: 40px;
  position: relative;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 36px;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-discover-alle {
    max-width: 1310px;
  }
}

#for-professionals .for-professionals-discover-alle__image-wrapper {
  justify-content: center;
  display: flex;
}

#for-professionals .for-professionals-discover-alle__background-image--mobile {
  display: block;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__background-image--mobile {
    display: none;
  }
}

#for-professionals .for-professionals-discover-alle__background-image--desktop {
  display: none;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__background-image--desktop {
    display: block;
  }
}

#for-professionals .for-professionals-discover-alle__background-image--desktop img {
  width: 1310px;
}

#for-professionals .for-professionals-discover-alle__content-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 76px;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__content-wrapper {
    max-width: 50%;
    margin-left: 44px;
    top: 100px;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-discover-alle__content-wrapper {
    max-width: 737px;
    margin-left: 88px;
    top: 114px;
  }
}

#for-professionals .for-professionals-discover-alle__text {
  margin-top: 33px;
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-discover-alle__text {
    margin-top: 41px;
    margin-bottom: 42px;
  }
}

#for-professionals .for-professionals-discover-alle__text p {
  font-family: var(--emu-semantic-font-families-heading);
  --figma-design-font-diff: 4.5px;
  --font-size: 24px;
  font-size: 20px;
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: -.72px;
  max-width: 301px;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__text p {
    font-style: 25px;
    letter-spacing: -1.26px;
    max-width: none;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-discover-alle__text p {
    font-size: 37px;
  }
}

#for-professionals .for-professionals-discover-alle__cta {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-50);
  padding: 17px 39px;
}

#for-professionals .for-professionals-discover-alle__cta:hover, #for-professionals .for-professionals-discover-alle__cta:active {
  background-color: var(--emu-semantic-colors-primary-50);
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__cta {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: 29px;
    padding-right: 29px;
  }
}

#for-professionals .for-professionals-discover-alle__contact-us-text {
  max-width: 352px;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__contact-us-text {
    margin-top: var(--emu-common-spacing-large);
    max-width: 910px;
  }
}

@media (min-width: 1440px) {
  #for-professionals .for-professionals-discover-alle__contact-us-text {
    max-width: 1158px;
  }
}

#for-professionals .for-professionals-discover-alle__contact-us-text p {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: 24px;
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: -.72px;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  #for-professionals .for-professionals-discover-alle__contact-us-text p {
    letter-spacing: -1.26px;
    padding-top: 26px;
    padding-bottom: 26px;
    font-size: 42px;
  }
}

#for-professionals .for-professionals-discover-alle__contact-us-text p a {
  color: inherit;
  text-decoration: none;
}

#proven-results .dg-tabs__section {
  margin-bottom: 37px;
  margin-left: 12px;
  margin-right: 12px;
}

@media (min-width: 880px) {
  #proven-results .dg-tabs__section {
    max-width: 851px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__section {
    max-width: 1093px;
    margin-bottom: 138px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 1280px) {
  #proven-results .dg-tabs__section {
    padding: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-xl);
  }
}

@media (min-width: 1440px) {
  #proven-results .dg-tabs__section {
    margin-left: auto;
  }
}

#proven-results .dg-tabs__title {
  letter-spacing: -.8px;
  margin-top: 53px;
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__title {
    letter-spacing: -1.2dppx;
    margin-top: 56px;
    margin-bottom: 66px;
  }

  #proven-results .dg-tabs__nav-text, #proven-results .dg-tabs__nav-links {
    letter-spacing: 1.2px;
    font-size: 18px;
  }
}

#proven-results .dg-tabs__nav-text {
  letter-spacing: 1.6px;
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__nav-text {
    letter-spacing: 1.8px;
    line-height: 27px;
  }
}

#proven-results .dg-tabs__nav-text br {
  display: none;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__nav-text br {
    display: block;
  }
}

#proven-results .dg-tabs__nav-links {
  margin-bottom: var(--emu-common-spacing-medium);
  line-height: 16px;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__nav-links {
    line-height: 20px;
  }
}

#proven-results .dg-tabs__nav-links .emphasis {
  display: none;
}

#proven-results .dg-tabs__nav-links a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

#proven-results .dg-tabs__nav-links.nav--active {
  color: var(--emu-semantic-colors-primary-50);
}

#proven-results .dg-tabs__nav-links.nav--active .emphasis {
  color: var(--emu-common-colors-black);
  display: block;
}

#proven-results .dg-tabs__nav-links.nav--active a {
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__nav-section {
    max-width: 199px;
  }
}

#proven-results .dg-tabs__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  max-width: 815px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__text {
    padding: var(--emu-common-spacing-none);
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: 20px;
  }

  #proven-results .dg-tabs__content {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }
}

@media (min-width: 1440px) {
  #proven-results .dg-tabs__content {
    position: relative;
    right: 10%;
  }
}

#proven-results .dg-tabs__ba-section {
  grid-gap: 11px;
  flex-direction: row;
  justify-content: center;
  gap: 11px;
  margin-top: 5px;
  margin-bottom: 28px;
  display: flex;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__ba-section {
    margin-top: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1280px) {
  #proven-results .dg-tabs__ba-section {
    grid-gap: 23px;
    gap: 23px;
  }
}

#proven-results .dg-tabs__ba-section-wrapper {
  display: none;
}

#proven-results .dg-tabs__ba-section-wrapper.tab--active {
  display: block;
}

#proven-results .dg-tabs__ba-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-common-line-heights-narrow-large);
  letter-spacing: 1.2px;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  #proven-results .dg-tabs__ba-text {
    letter-spacing: 1.5px;
    margin-top: 21px;
    font-size: 15px;
    line-height: 23px;
  }
}

#proven-results .dg-tabs__ba-text .emphasis {
  display: inline-block;
}

#proven-results .dg-results__section {
  position: relative;
}

#proven-results .dg-results__content {
  padding-top: 38px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 43px;
  padding-left: var(--emu-common-spacing-small);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__content {
    padding-top: 25px;
  }
}

#proven-results .dg-results__title {
  margin-bottom: 42px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__title {
    margin-bottom: 13px;
  }

  #proven-results .dg-results__title br {
    display: none;
  }

  #proven-results .dg-results__title .cmp-title__text {
    line-height: 90px;
  }
}

#proven-results .dg-results__sub-text {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  letter-spacing: -.45px;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__sub-text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-medium);
    letter-spacing: -.57px;
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#proven-results .dg-results__text {
  font-size: var(--emu-common-line-heights-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  letter-spacing: -.54px;
  max-width: 500px;
  margin-bottom: 43px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__text {
    font-size: 38px;
    line-height: var(--emu-common-line-heights-wide-xl);
    letter-spacing: -1.14px;
    max-width: 952px;
  }
}

#proven-results .dg-results__text p {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-common-font-weight-light);
}

#proven-results .dg-results__percentage {
  margin-bottom: 13px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__percentage {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#proven-results .dg-results__percentage .cmp-title__text {
  letter-spacing: -1.65px;
  font-size: 55px;
  line-height: 55px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__percentage .cmp-title__text {
    letter-spacing: -2.64px;
    font-size: 88px;
    line-height: 88px;
  }
}

#proven-results .dg-results__percentage-container {
  grid-gap: 25px;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  display: flex;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__percentage-container {
    grid-gap: 70px;
    gap: 70px;
  }
}

#proven-results .dg-results__percentage-container .dg-results__text {
  max-width: 166px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__percentage-container .dg-results__text {
    letter-spacing: -.81px;
    max-width: 323px;
    margin-bottom: 46px;
    font-size: 27px;
    line-height: 33px;
  }

  #proven-results .dg-results__percentage-container .dg-results__text br {
    display: none;
  }
}

#proven-results .dg-results__percentage-container .dg-results__text .emphasis {
  letter-spacing: -.42px;
  font-size: 14px;
  line-height: 17px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__percentage-container .dg-results__text .emphasis {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    letter-spacing: -.57px;
    line-height: 23px;
  }
}

#proven-results .dg-results__small-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  letter-spacing: -.36px;
}

@media (min-width: 1024px) {
  #proven-results .dg-results__small-text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    letter-spacing: -.6px;
    margin-top: 21px;
    line-height: 25px;
  }
}

#proven-results .dg-satisfaction__section {
  position: relative;
}

#proven-results .dg-satisfaction__section .dg-results__title {
  margin-bottom: 63px;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__section .dg-results__title {
    margin-bottom: 69px;
  }
}

#proven-results .dg-satisfaction__section .dg-results__sub-text {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__section .dg-results__sub-text {
    min-height: 58px;
    margin-bottom: 24px;
  }

  #proven-results .dg-satisfaction__section .dg-results__small-text {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: var(--emu-semantic-line-heights-wide-medium);
    letter-spacing: -.57px;
    margin-top: var(--emu-common-spacing-none);
  }
}

#proven-results .dg-satisfaction__content {
  grid-gap: 68px;
  flex-direction: column;
  justify-content: center;
  gap: 68px;
  display: flex;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__content {
    grid-gap: var(--emu-common-spacing-none);
    gap: var(--emu-common-spacing-none);
    flex-direction: row;
    max-width: 1088px;
    margin-left: auto;
    margin-right: auto;
  }

  #proven-results .dg-satisfaction__content > .container {
    flex: 1;
  }
}

#proven-results .dg-satisfaction__content > .container:first-child .dg-satisfaction__inner-section {
  position: relative;
}

#proven-results .dg-satisfaction__content > .container:first-child .dg-satisfaction__inner-section:after {
  content: "";
  background-color: var(--emu-common-colors-black);
  width: calc(100% - 48px);
  height: 1px;
  display: block;
  position: absolute;
  bottom: -39px;
  left: 24px;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__content > .container:first-child .dg-satisfaction__inner-section:after {
    bottom: unset;
    left: unset;
    width: 1px;
    height: 100%;
    top: 0;
    right: -21px;
  }
}

#proven-results .dg-satisfaction__content-wrapper {
  padding-top: 38px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: 52px;
  padding-left: var(--emu-common-spacing-small);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__content-wrapper {
    padding: var(--emu-common-spacing-none);
    padding-top: 42px;
    padding-bottom: 80px;
  }
}

#proven-results .dg-satisfaction__main-text {
  letter-spacing: -.84px;
  margin-bottom: 25px;
  font-size: 28px;
  line-height: 28px;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__main-text {
    letter-spacing: -1.98px;
    margin-bottom: 50px;
    font-size: 66px;
    line-height: 66px;
  }
}

#proven-results .dg-satisfaction__main-text p {
  font-weight: var(--emu-common-font-weight-light);
  font-family: var(--emu-semantic-font-families-heading);
}

#proven-results .dg-satisfaction__main-text .emphasis {
  letter-spacing: -1.65px;
  font-size: 55px;
  line-height: 55px;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__main-text .emphasis {
    letter-spacing: -2.64px;
    font-size: 88px;
    line-height: 88px;
  }
}

#proven-results .dg-satisfaction__text {
  font-size: var(--emu-common-line-heights-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: -.54px;
  margin-bottom: 25px;
}

@media (min-width: 1024px) {
  #proven-results .dg-satisfaction__text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    letter-spacing: -.72px;
    margin-bottom: 38px;
  }
}

#proven-results .dg-results__section .aaaem-teaser, #proven-results .dg-satisfaction__section .aaaem-teaser {
  position: static;
}

#proven-results .dg-results__section .aaaem-teaser__image img, #proven-results .dg-satisfaction__section .aaaem-teaser__image img {
  z-index: var(--emu-common-other-z-index-behind);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#skm-take-home .product-grid-container__intro-text {
  max-width: 747px;
  margin: 50px auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__intro-text {
    margin-top: 53px;
    margin-bottom: 39px;
  }
}

#skm-take-home .product-grid-container__intro-text h3, #skm-take-home .product-grid-container__intro-text h2, #skm-take-home .product-grid-container__intro-text h4, #skm-take-home .product-grid-container__intro-text h5 {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  letter-spacing: -.72px;
  line-height: 30px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__intro-text h3, #skm-take-home .product-grid-container__intro-text h2, #skm-take-home .product-grid-container__intro-text h4, #skm-take-home .product-grid-container__intro-text h5 {
    letter-spacing: -1.26px;
    font-size: 42px;
    line-height: 52.5px;
  }
}

#skm-take-home .product-grid-container__title {
  padding: 37px 48px 15px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__title {
    padding-left: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-xl);
    padding-top: 41px;
    padding-bottom: 22px;
  }
}

#skm-take-home .product-grid-container__title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-50);
  font-family: var(--emu-semantic-font-families-body);
  letter-spacing: .54px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__title .cmp-title__text {
    letter-spacing: 2.1px;
    font-size: 21px;
    line-height: 31.5px;
  }
}

#skm-take-home .product-grid-container__description {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 12px;
  padding-left: 44px;
  padding-right: 44px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__description {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 650px;
  }
}

#skm-take-home .product-grid-container__description p {
  letter-spacing: -.48px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container__description p {
    letter-spacing: -.72px;
  }
}

#skm-take-home .product-grid-container .product-grid--space-large {
  padding-top: 66px;
  padding-bottom: 66px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-grid--space-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

#skm-take-home .product-grid-container .product-grid__item {
  height: 100%;
}

#skm-take-home .product-grid-container .product-grid__item--solo-image img {
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 389px;
  max-height: 389px;
}

@media (min-width: 768px) {
  #skm-take-home .product-grid-container .product-grid__item--solo-image img {
    height: auto;
    max-height: 608px;
  }
}

#skm-take-home .product-grid-container .product-grid__item--solo-image .container, #skm-take-home .product-grid-container .product-grid__item--solo-image .aaaem-container, #skm-take-home .product-grid-container .product-grid__item--solo-image .image, #skm-take-home .product-grid-container .product-grid__item--solo-image .aaaem-image, #skm-take-home .product-grid-container .product-grid__item--solo-image img {
  height: 100%;
}

#skm-take-home .product-grid-container .product-grid__item-image {
  transition: all .6s ease-in-out;
}

#skm-take-home .product-grid-container .product-grid__item-image:hover {
  transform: scale(1.2);
}

#skm-take-home .product-grid-container .product-grid__item-image-container {
  overflow: hidden;
}

#skm-take-home .product-grid-container .product-grid__item > .container {
  height: 100%;
}

#skm-take-home .product-grid-container .product-grid__item .card a {
  height: 100%;
  display: inline-block;
}

#skm-take-home .product-grid-container .product-card, #skm-take-home .product-grid-container .product-card .aaaem-card__wrapper, #skm-take-home .product-grid-container .product-card .aaaem-card__content-wrapper, #skm-take-home .product-grid-container .product-card .aaaem-card__footer {
  height: 100%;
}

#skm-take-home .product-grid-container .product-card div[data-ref="card-content"], #skm-take-home .product-grid-container .product-card__text-container {
  flex-direction: column;
  height: 100%;
  display: flex;
}

#skm-take-home .product-grid-container .product-card__text-container > .text {
  flex-grow: 1;
}

#skm-take-home .product-grid-container .product-card:hover .product-card__img--primary img {
  transform: scale(1.2);
}

#skm-take-home .product-grid-container .product-card__title .cmp-title__text {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-xxs);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: -.48px;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__title .cmp-title__text {
    margin-bottom: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
    letter-spacing: -.6px;
  }
}

#skm-take-home .product-grid-container .product-card__title--line-height-small .cmp-title__text {
  line-height: 17.6px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__title--line-height-small .cmp-title__text {
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

#skm-take-home .product-grid-container .product-card__text-container.aaaem-container.cmp-container {
  padding-top: 13px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__text-container.aaaem-container.cmp-container {
    padding-top: var(--emu-common-spacing-medium);
  }
}

#skm-take-home .product-grid-container .product-card__text-container--padding-sm.aaaem-container.cmp-container {
  padding-top: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__text-container--padding-sm.aaaem-container.cmp-container {
    padding-top: var(--emu-common-spacing-medium);
  }
}

#skm-take-home .product-grid-container .product-card__text {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
  font-size: 14px;
  line-height: 21px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: -.42px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-common-line-heights-wide-medium);
    letter-spacing: -.48px;
  }
}

#skm-take-home .product-grid-container .product-card__text--margin-xxs {
  margin-bottom: var(--emu-common-spacing-xxs);
}

#skm-take-home .product-grid-container .product-card__text--margin-sm {
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #skm-take-home .product-grid-container .product-card__text--margin-xxs, #skm-take-home .product-grid-container .product-card__text--margin-sm {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#skm-take-home .product-grid-container .product-card__img {
  overflow: hidden;
}

#skm-take-home .product-grid-container .product-card__img--primary img {
  height: 229px;
  transition: all .6s ease-in-out;
}

@media (min-width: 768px) {
  #skm-take-home .product-grid-container .product-card__img--primary img {
    height: 412px;
  }
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__img--primary img {
    height: 438px;
  }
}

#skm-take-home .product-grid-container .product-card__img--stamp-small img {
  width: 44px;
  bottom: 10px;
  right: 10px;
}

@media (min-width: 1024px) {
  #skm-take-home .product-grid-container .product-card__img--stamp-small img {
    width: 76px;
    bottom: 15px;
    right: 15px;
  }
}

#treatment .video-features-container__intro-text {
  max-width: 952px;
  margin: 34px 30px 46px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__intro-text {
    margin: 60px auto 98px;
  }
}

#treatment .video-features-container__intro-text h3 {
  font-size: var(--emu-common-font-sizes-narrow-xl);
  letter-spacing: -.72px;
  line-height: 30px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__intro-text h3 {
    letter-spacing: -1.26px;
    font-size: 42px;
    line-height: 52.5px;
  }
}

#treatment .video-features-container__wrapper {
  grid-gap: 28px;
  flex-direction: column;
  justify-content: center;
  gap: 28px;
  margin-bottom: 59px;
  margin-left: 28px;
  margin-right: 28px;
  display: flex;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__wrapper {
    grid-gap: var(--emu-common-spacing-xl);
    align-items: center;
    gap: var(--emu-common-spacing-xl);
    margin-left: var(--emu-common-spacing-large);
    margin-right: var(--emu-common-spacing-large);
    flex-direction: row;
    margin-bottom: 39px;
  }
}

@media (min-width: 1280px) {
  #treatment .video-features-container__wrapper {
    grid-gap: 135px;
    align-items: flex-start;
    gap: 135px;
    margin-left: 66px;
    margin-right: 66px;
  }
}

#treatment .video-features-container--left {
  width: 336px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #treatment .video-features-container--left {
    width: 563px;
  }
}

@media (min-width: 1280px) {
  #treatment .video-features-container--left {
    width: 643px;
  }
}

#treatment .video-features-container__video {
  border: 15px solid var(--emu-semantic-colors-secondary-100);
  background-color: var(--emu-semantic-colors-secondary-100);
}

@media (min-width: 1024px) {
  #treatment .video-features-container__video {
    border-width: 20px;
  }
}

#treatment .video-features-container--right {
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__title {
    margin-top: 35px;
  }
}

#treatment .video-features-container__title h3 {
  font-family: var(--emu-semantic-font-families-body);
  letter-spacing: .66px;
  text-align: center;
  font-size: 22px;
  line-height: 33px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__title h3 {
    font-size: var(--emu-common-font-sizes-wide-xl);
    line-height: var(--emu-common-line-heights-wide-xl);
    letter-spacing: .96px;
    text-align: left;
  }
}

#treatment .video-features-container__description {
  margin-left: var(--emu-common-spacing-xs);
  margin-right: var(--emu-common-spacing-xs);
  letter-spacing: -.48px;
  text-align: center;
  max-width: 640px;
  margin-top: 11px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__description {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    letter-spacing: -.72px;
    text-align: left;
    max-width: 518px;
    margin-top: 14px;
  }
}

#treatment .video-features-container__description p {
  font-weight: var(--emu-common-font-weight-regular);
}

#treatment .video-features-container__list {
  grid-gap: 24px;
  flex-direction: column;
  gap: 24px;
  margin-top: 10px;
  display: flex;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__list {
    grid-gap: 45px;
    gap: 45px;
    margin-top: 28px;
    margin-left: -6px;
  }
}

#treatment .video-features-container__list-item {
  grid-gap: 11px;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  display: flex;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__list-item {
    grid-gap: var(--emu-common-spacing-medium);
    gap: var(--emu-common-spacing-medium);
    flex-direction: row;
  }
}

#treatment .video-features-container__list-item-icon {
  max-width: 105px;
}

#treatment .video-features-container__list-item-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: -.48px;
  text-align: center;
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
  max-width: 640px;
  line-height: 20.8px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__list-item-text {
    letter-spacing: -.54px;
    text-align: left;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    max-width: 390px;
    font-size: 18px;
    line-height: 23.4px;
  }
}

#treatment .video-features-container__list-item-text .emphasis {
  color: var(--emu-semantic-colors-primary-50);
  letter-spacing: 1.6px;
}

@media (min-width: 1024px) {
  #treatment .video-features-container__list-item-text .emphasis {
    letter-spacing: 1.8px;
  }
}

#treatment .video-features-container__list-item-text p {
  font-weight: var(--emu-common-font-weight-regular);
}

#treatment .video-features-container__list-item--last {
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #treatment .video-features-container__list-item--last {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }

  #treatment .image-text-overlay__teaser {
    margin-left: 66px;
    margin-right: 66px;
  }
}

#treatment .image-text-overlay__teaser .content-container {
  padding-top: 42px;
}

@media (min-width: 1024px) {
  #treatment .image-text-overlay__teaser .content-container {
    padding-top: 73px;
  }
}

#treatment .image-text-overlay__teaser .cmp-teaser__title {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  letter-spacing: -.8px;
  padding-bottom: 21px;
  padding-top: var(--emu-common-spacing-none);
  text-shadow: none;
}

@media (min-width: 1024px) {
  #treatment .image-text-overlay__teaser .cmp-teaser__title {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    letter-spacing: -1.2px;
  }
}

@media (min-width: 768px) {
  #treatment .image-text-overlay__teaser .cmp-teaser__title br {
    display: none;
  }
}

#treatment .image-text-overlay__teaser .cmp-teaser__description {
  max-width: 626px;
  margin-left: auto;
  margin-right: auto;
}

#treatment .image-text-overlay__teaser .cmp-teaser__description p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: -.54px;
}

@media (min-width: 1024px) {
  #treatment .image-text-overlay__teaser .cmp-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
    letter-spacing: -.72px;
  }
}

@media (min-width: 768px) {
  #treatment .image-text-overlay__teaser .cmp-teaser__description p br {
    display: none;
  }
}

#treatment .image-text-overlay__teaser .disclaimer {
  color: var(--emu-common-colors-white);
  left: 27px;
}

@media (min-width: 1024px) {
  #treatment .image-text-overlay__teaser .disclaimer {
    display: block;
    bottom: 24px;
    left: 31px;
  }
}

#treatment .dg-fap__teaser--small {
  margin-bottom: 23px;
}

@media (min-width: 1024px) {
  #treatment .dg-fap__teaser--small {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

.aaaem-accordion {
  border-bottom: var(--emu-component-lists-accordion-item-border-width-bottom-open) solid var(--emu-component-lists-accordion-color-background-dark);
}

.aaaem-accordion__title {
  letter-spacing: -.75px;
  font-size: 25px;
  line-height: 34px;
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  .aaaem-accordion__title {
    font-size: 40px;
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    letter-spacing: -1.2px;
  }
}

.aaaem-accordion__icon {
  margin-right: 14px;
}

@media (min-width: 1024px) {
  .aaaem-accordion__icon {
    margin-right: var(--emu-common-spacing-none);
    width: 20px;
    height: 20px;
  }

  .aaaem-accordion__icon:before, .aaaem-accordion__icon:after {
    width: 4px;
  }
}

.aaaem-accordion__panel .aaaem-text {
  letter-spacing: -.6px;
  font-size: 20px;
  line-height: 26px;
}

@media (min-width: 1024px) {
  .aaaem-accordion__panel .aaaem-text {
    letter-spacing: -.9px;
    font-size: 30px;
    line-height: 45px;
  }
}

.aaaem-accordion__header {
  border-bottom: none;
  border-top: var(--emu-component-lists-accordion-item-border-width-bottom-open) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
}

.aaaem-accordion dt:first-child .aaaem-accordion__header {
  border-top: none;
}

.aaaem-accordion .js-badger-accordion-panel-inner {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: 30px;
}



.button {
  text-align: center;
}

.button .aaaem-button {
  font: var(--emu-component-actions-button-text-typography-narrow);
  letter-spacing: 3.2px;
  padding-top: var(--emu-component-actions-button-padding-top-narrow);
  padding-right: var(--emu-component-actions-button-padding-right-narrow);
  padding-bottom: var(--emu-component-actions-button-padding-bottom-narrow);
  padding-left: var(--emu-component-actions-button-padding-left-narrow);
  justify-content: center;
}

@media (min-width: 1024px) {
  .button .aaaem-button {
    font: var(--emu-component-actions-button-text-typography-wide);
    letter-spacing: 3.6px;
    padding-top: var(--emu-component-actions-button-padding-top-wide);
    padding-right: var(--emu-component-actions-button-padding-right-wide);
    padding-bottom: var(--emu-component-actions-button-padding-bottom-wide);
    padding-left: var(--emu-component-actions-button-padding-left-wide);
  }
}

.button .aaaem-button__secondary-filled {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: 3.6px;
}

.button .aaaem-button__secondary-outline {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: 1.2px;
}

@media (min-width: 1024px) {
  .button .aaaem-button__secondary-outline {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-common-line-heights-wide-large);
    letter-spacing: 3.2px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

.card a {
  text-decoration: none;
}

.card .button {
  position: static;
}

.aaaem-carousel {
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .aaaem-carousel {
    margin-right: 65px;
  }
}

.aaaem-carousel .tns-outer .tns-ovh {
  height: 100% !important;
}

.aaaem-carousel__actions {
  position: absolute;
  bottom: -26px;
  right: 21px;
}

@media (min-width: 1024px) {
  .aaaem-carousel__actions {
    top: 35%;
    bottom: auto;
    bottom: initial;
    right: auto;
    right: initial;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
}

.aaaem-carousel__action {
  padding: var(--emu-common-spacing-none);
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  bottom: -8px;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action {
    bottom: 50%;
  }
}

.aaaem-carousel__action:disabled {
  opacity: 0;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action:disabled {
    opacity: 1;
    opacity: initial;
  }
}

.aaaem-carousel__action svg {
  fill: var(--emu-common-colors-black);
  width: 24px;
  height: 24px;
  display: block;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action svg {
    width: 20px;
    height: 20px;
  }
}

.aaaem-carousel__action-prev {
  margin: var(--emu-common-spacing-none);
  left: -20px;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action-prev {
    right: unset;
    left: -40px;
  }
}

.aaaem-carousel__action-next {
  right: -5px;
}

@media (min-width: 1024px) {
  .aaaem-carousel__action-next {
    right: -40px;
  }
}

.aaaem-carousel__content {
  margin-left: auto;
  margin-right: auto;
}

.cmp-experiencefragment--ISI {
  max-width: var(--emu-component-containers-container-max-width);
  margin-left: auto;
  margin-right: auto;
}

.container-isi .aaaem-isi-container__banner-content p {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  letter-spacing: .33px;
  font-size: 11px;
  line-height: 16.5px;
  font-weight: var(--emu-semantic-font-weight-500);
  opacity: .9;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__banner-content p {
    letter-spacing: .45px;
    font-size: 15px;
    line-height: 22.5px;
  }
}

.container-isi .aaaem-isi-container__button-wrapper .aaaem-button {
  padding: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  letter-spacing: .48px;
  font-weight: var(--emu-semantic-font-weight-500);
  grid-gap: var(--emu-common-spacing-small);
  gap: var(--emu-common-spacing-small);
  margin-top: 5px;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__button-wrapper .aaaem-button {
    letter-spacing: .45px;
    margin-top: 3px;
    font-size: 15px;
    line-height: 22.5px;
  }
}

.container-isi .aaaem-isi-container__button-wrapper .aaaem-button .cmp-button__text {
  display: none;
}

@media (min-width: 1024px) {
  .container-isi .aaaem-isi-container__button-wrapper .aaaem-button .cmp-button__text {
    display: block;
  }
}

.container-isi .aaaem-isi-container__button-wrapper .aaaem-button svg {
  stroke: var(--emu-common-colors-white);
  stroke-width: 1.5px;
  width: 14px;
}

.container-isi .aaaem-isi-container__isi-content .aaaem-text {
  font-size: var(--emu-common-font-sizes-wide-medium);
  line-height: var(--emu-common-line-heights-wide-medium);
  letter-spacing: .48px;
  font-weight: var(--emu-common-font-weight-regular);
}

.aaaem-back-to-top-button {
  background-color: var(--emu-common-colors-black);
  color: var(--emu-common-colors-white);
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: .48px;
  z-index: var(--emu-common-other-z-index-overlay);
  justify-content: center;
  width: 100%;
  max-height: 44px;
  padding: 11px;
  text-decoration: none;
}

.aaaem-back-to-top-button .cmp-button__text {
  opacity: .9;
}

@media (min-width: 1024px) {
  .aaaem-back-to-top-button {
    letter-spacing: .45px;
    font-size: 15px;
    line-height: 22.5px;
  }
}

.plyr__controls .plyr__controls__item.plyr__volume {
  min-width: var(--emu-common-spacing-none);
  width: auto;
  max-width: none;
}

.dg-fap__teaser {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .dg-fap__teaser {
    margin: 65px 70px;
  }
}

.dg-fap__teaser--small {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .dg-fap__teaser--small {
    margin: 65px;
  }
}

.dg-fap__teaser--small .cmp-teaser__image--mobile img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 299px;
}

.dg-fap__teaser .cmp-teaser__image img {
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 220px;
}

.dg-fap__teaser .content-container {
  padding: var(--emu-common-spacing-none);
  background: linear-gradient(#a57f74bf 20%, #a57f743b 53.86%, #a57f7400 95.31%);
  width: 100%;
  padding-top: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1280px) {
  .dg-fap__teaser .content-container {
    padding-top: 54px;
  }
}

.dg-fap__teaser .content-container .aaaem-teaser__title {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
  letter-spacing: -.8px;
  padding: var(--emu-common-spacing-none);
  text-shadow: none;
  margin-bottom: 37px;
}

@media (min-width: 1024px) {
  .dg-fap__teaser .content-container .aaaem-teaser__title {
    letter-spacing: -1.2px;
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }

  .dg-fap__teaser .content-container .aaaem-teaser__title br {
    display: none;
  }
}

.dg-fap__teaser .content-container .aaaem-teaser__action-container {
  margin-top: var(--emu-common-spacing-none);
}

.dg-fap__teaser .content-container .aaaem-teaser__action-link {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: 3.6px;
  background-color: var(--emu-common-colors-white);
  color: var(--emu-semantic-colors-primary-50);
  font-weight: var(--emu-common-font-weight-bold);
  padding: 12.5px 41px;
  text-decoration: none;
  display: inline-block;
}

.dg-fap__teaser .content-container .aaaem-teaser__action-link:hover, .dg-fap__teaser .content-container .aaaem-teaser__action-link:active {
  background-color: var(--emu-semantic-colors-primary-50);
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .dg-fap__teaser .content-container .aaaem-teaser__action-link {
    padding: 16.5px 62px;
  }
}

.dg-fap__teaser .content-container .title-lockup {
  max-width: 100%;
}

.dg-fap__teaser .content-container .disclaimer {
  bottom: 23px;
  left: 19px;
}

@media (min-width: 1024px) {
  .dg-fap__teaser .content-container .disclaimer {
    left: var(--emu-common-sizing-small);
    bottom: 14px;
  }
}

.dg-fap__teaser .content-container .disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: -.36px;
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .dg-fap__teaser .content-container .disclaimer p {
    letter-spacing: -.42px;
    font-size: 14px;
    line-height: 21px;
  }
}

footer .cmp-experiencefragment--footer {
  max-width: var(--emu-component-containers-container-max-width);
  margin-left: auto;
  margin-right: auto;
}

footer.footer {
  background-image: url("resources/images/footer-bg-mobile.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 45px 30px 12px;
}

@media (min-width: 1024px) {
  footer.footer {
    background-image: url("resources/images/footer-bg-desktop.png");
    padding: 30px 15px 21px;
  }
}

@media (min-width: 1280px) {
  footer.footer {
    padding-left: 60px;
    padding-right: 60px;
  }
}

footer .footer .aaaem-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: -.24px;
}

footer .footer .aaaem-text p {
  font-weight: var(--emu-semantic-font-weight-600);
}

footer .footer .aaaem-text a {
  color: inherit;
  text-decoration: none;
}

footer .footer__logo .aaaem-image {
  max-width: 192px;
  margin-bottom: 35px;
}

footer .footer__top-container {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  footer .footer__top-container {
    border-bottom-width: var(--emu-common-border-width-thin);
    border-bottom-style: solid;
    border-bottom-color: #48628429;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
    display: flex;
  }

  footer .footer__image {
    max-width: 192px;
    margin-top: 26px;
    margin-bottom: 82px;
  }
}

@media (min-width: 1280px) {
  footer .footer__image {
    max-width: none;
  }
}

footer .footer__image-section {
  display: none;
}

@media (min-width: 1024px) {
  footer .footer__image-section {
    display: block;
  }
}

footer .footer__social-container {
  grid-gap: 23px;
  flex-direction: row;
  justify-content: center;
  gap: 23px;
  margin-bottom: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  footer .footer__social-container {
    margin-bottom: var(--emu-common-spacing-none);
    justify-content: flex-start;
  }
}

footer .footer__nav-container {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  footer .footer__nav-container {
    grid-gap: 35px;
    margin-bottom: var(--emu-common-spacing-none);
    flex-direction: row;
    gap: 35px;
    display: flex;
  }
}

@media (min-width: 1140px) {
  footer .footer__nav-container {
    grid-gap: 50px;
    gap: 50px;
  }
}

footer .footer__nav-container .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-wide-large);
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  footer .footer__nav-container .aaaem-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
    letter-spacing: -.4px;
    margin-bottom: 24px;
  }
}

footer .footer__nav-container .aaaem-text ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
}

footer .footer__nav-container .aaaem-text ul li {
  font-size: 13px;
  line-height: var(--emu-common-line-heights-narrow-large);
  margin-bottom: var(--emu-common-spacing-medium);
  font-weight: var(--emu-semantic-font-weight-500);
  list-style: none;
}

@media (min-width: 1024px) {
  footer .footer__nav-container .aaaem-text ul li {
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-common-line-heights-wide-medium);
    letter-spacing: -.32px;
    margin-bottom: 23px;
  }
}

footer .footer__nav-links {
  border-bottom-width: var(--emu-common-border-width-thin);
  margin-bottom: var(--emu-common-spacing-medium);
  border-bottom-style: solid;
  border-bottom-color: #000;
}

@media (min-width: 1024px) {
  footer .footer__nav-links {
    border: var(--emu-common-border-width-none);
    margin: var(--emu-common-spacing-none);
  }
}

footer .footer__nav-links li .emphasis {
  flex-direction: row;
  align-items: center;
  display: flex;
}

footer .footer__nav-links li .emphasis img {
  max-width: 31px;
  display: inline-block;
}

@media (min-width: 1024px) {
  footer .footer__nav-links li .emphasis img {
    max-width: 50px;
  }

  footer .footer__bottom-container .footer__social-container {
    display: none;
  }
}

footer .footer__bottom-text.aaaem-text {
  text-align: center;
}

@media (min-width: 1024px) {
  footer .footer__bottom-text.aaaem-text {
    text-align: left;
    font-size: var(--emu-common-font-sizes-wide-medium);
    line-height: var(--emu-common-line-heights-wide-medium);
    letter-spacing: -.32px;
    margin-top: 15px;
  }
}

footer .footer__bottom-text.aaaem-text p {
  font-weight: var(--emu-semantic-font-weight-500);
}

footer__img-link {
  grid-gap: 10px;
  grid-gap: 10px;
  align-items: center;
  gap: 10px;
  display: flex;
}

footer__img-link img {
  max-width: 40px;
  display: inline-block;
}

.gallery-redirector__wrapper {
  grid-gap: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin: auto;
  padding: 43px 20px 51px;
  display: flex;
}

@media (min-width: 1024px) {
  .gallery-redirector__wrapper {
    flex-direction: row;
    padding: 53px 70px 51px 112px;
  }
}

@media (min-width: 1440px) {
  .gallery-redirector__wrapper {
    grid-gap: 200px;
    justify-content: center;
    gap: 200px;
  }
}

.gallery-redirector__text-content {
  grid-gap: 21px;
  flex-direction: column;
  justify-content: center;
  gap: 21px;
  display: flex;
}

@media (min-width: 1024px) {
  .gallery-redirector__text-content {
    grid-gap: 42px;
    gap: 42px;
    width: 423px;
    max-width: 100%;
  }
}

.gallery-redirector__text-content h2 {
  letter-spacing: -.8px;
  margin-bottom: 23px;
}

@media (min-width: 1024px) {
  .gallery-redirector__text-content h2 {
    letter-spacing: -1.2px;
    margin-bottom: 33px;
  }
}

.gallery-redirector__text-content .cmp-text p {
  letter-spacing: -.48px;
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  max-width: 640px;
}

@media (min-width: 1024px) {
  .gallery-redirector__text-content .cmp-text p {
    letter-spacing: -.72px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.gallery-redirector__cta {
  min-width: 248px;
}

@media (min-width: 1024px) {
  .gallery-redirector__cta {
    min-width: 285px;
  }
}

.gallery-redirector__image-content-wrapper {
  grid-gap: 34px;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  display: flex;
}

@media (min-width: 1024px) {
  .gallery-redirector__image-content-wrapper {
    grid-gap: 29px;
    gap: 29px;
  }
}

.gallery-redirector__image-content {
  grid-gap: 20px;
  flex-direction: row;
  gap: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .gallery-redirector__image-content {
    grid-gap: 29px;
    gap: 29px;
  }
}

.gallery-redirector__image-content .image {
  max-width: 168px;
}

@media (min-width: 768px) {
  .gallery-redirector__image-content .image {
    max-width: 303px;
  }
}

.gallery-redirector__image-text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: 1.2px;
  margin-top: 14px;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .gallery-redirector__image-text {
    letter-spacing: 1.5px;
    margin-top: 17px;
    font-size: 15px;
  }
}

.gallery-redirector__image-text p {
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  .gallery-redirector__image-text p {
    padding-bottom: var(--emu-common-spacing-medium);
  }
}

.gallery-redirector__disclaimer-text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: -.36px;
  max-width: 336px;
  margin: auto;
  line-height: 15.6px;
}

@media (min-width: 768px) {
  .gallery-redirector__disclaimer-text {
    max-width: 577px;
  }
}

@media (min-width: 1024px) {
  .gallery-redirector__disclaimer-text p {
    padding-bottom: 25px;
  }
}

.header {
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-common-colors-white);
  width: 100%;
  transition: all .3s linear;
  position: fixed;
  top: 0;
}

.header .cmp-container-header {
  max-width: var(--emu-component-containers-container-max-width);
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .header .cmp-container-header {
    height: auto;
    display: block;
  }
}

.header__for-professionals {
  background-color: var(--emu-semantic-colors-primary-50);
  justify-content: right;
  padding-right: 23px;
  display: flex;
}

.header__for-professionals .button {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  align-items: center;
  height: 40px;
  display: flex;
}

.header__for-professionals .button a {
  padding: var(--emu-common-spacing-none);
  grid-gap: 5px;
  gap: 5px;
  text-decoration: none;
  display: flex;
}

.header__for-professionals .button a:hover {
  color: var(--emu-common-colors-white);
}

.header__for-professionals .button .cmp-button__text {
  font-size: 14px;
  font-weight: var(--emu-semantic-font-weight-500);
}

.header__nav-container {
  opacity: .5;
  background-color: var(--emu-common-colors-white);
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 81px);
  transition: all .3s ease-out;
  display: flex;
  position: fixed;
  top: 81px;
  left: 0;
  overflow: auto;
  transform: translateX(-100%);
}

@media (min-width: 1024px) {
  .header__nav-container {
    opacity: 1;
    left: unset;
    align-items: center;
    height: auto;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    top: 0;
    transform: none;
  }
}

.header__nav-container .image {
  align-items: center;
  display: flex;
}

.header__nav-container > div:first-child {
  width: 100%;
}

.header__nav-logo-container {
  justify-content: space-between;
  display: flex;
}

.header__nav-logo-container > div:first-child {
  margin-right: var(--emu-common-spacing-large);
  width: 42%;
}

.header__nav-logo-container > div:last-child {
  width: 44%;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(2) {
  position: relative;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(2):after {
  content: "";
  width: var(--emu-common-border-width-thin);
  background-color: var(--emu-semantic-colors-tertiary-50);
  height: 23px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -13px;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(3) {
  opacity: .75;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(3) a:hover, .header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(3) a:active {
  color: var(--emu-semantic-colors-primary-50);
  border-bottom-color: var(--emu-semantic-colors-none);
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:nth-child(3).emu-navigation__item--active a {
  font-weight: var(--emu-common-font-weight-regular);
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:last-child {
  background-color: var(--emu-semantic-colors-primary-50);
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:last-child:hover {
  opacity: .5;
  cursor: pointer;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:last-child a {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-common-colors-white);
  letter-spacing: -.45px;
  line-height: 23px;
  font-weight: var(--emu-semantic-font-weight-500);
  justify-content: center;
  min-width: 134px;
  padding: 6.5px 10px;
  display: flex;
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:last-child a:hover {
  border-bottom-color: var(--emu-semantic-colors-none);
}

.header__nav-logo-container > div:last-child .emu-navigation__menu--level-0 li:last-child.emu-navigation__item--active a {
  font-weight: var(--emu-semantic-font-weight-500);
}

.header__logo {
  margin: auto;
}

.header__logo .aaaem-image {
  width: 96px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  right: 25px;
}

.header__global-navigation {
  display: none;
}

.header.show-mobile-menu .header__menu-close.aaaem-button {
  display: block;
}

@media (min-width: 1024px) {
  .header.show-mobile-menu .header__menu-close.aaaem-button {
    display: none;
  }
}

.header.show-mobile-menu .header__menu-trigger.aaaem-button {
  display: none;
}

.header.show-mobile-menu .header {
  background-color: var(--emu-common-colors-white);
}

.header.show-mobile-menu .header__nav-container {
  opacity: 1;
  transition: all .3s ease-in;
  transform: translateX(0);
}

@media (min-width: 1024px) {
  .header.show-mobile-menu .header__nav-container {
    transform: none;
  }
}

.header__content .button button {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
}

.header__content .button button.header__menu-trigger {
  padding: var(--emu-common-spacing-none);
  background-color: #0000;
  border: none;
  width: 50px;
  height: 81px;
  padding-left: 5px;
  display: block;
}

@media (min-width: 1024px) {
  .header__content .button button.header__menu-trigger {
    display: none;
  }
}

.header__content .button button.header__menu-close {
  padding: var(--emu-common-spacing-none);
  background-color: #0000;
  border: none;
  width: 50px;
  height: 81px;
  padding-left: 10px;
  display: none;
}

@media (min-width: 1024px) {
  .sticky {
    transform: translateY(-40px);
  }
}

.emu-navigation__content-wrapper {
  display: block;
  position: static;
}

.emu-navigation__content-wrapper nav {
  background-color: var(--emu-common-colors-transparent);
  border-top: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  padding-top: 26px;
  padding-bottom: 26px;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    border-top: none;
  }
}

.emu-navigation__content-wrapper nav ul {
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav ul {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
}

.emu-navigation__content-wrapper nav ul li {
  width: 100%;
  margin-bottom: 1px;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav ul li {
    margin-bottom: var(--emu-common-spacing-none);
    width: auto;
  }
}

.emu-navigation__content-wrapper nav ul li a {
  font-size: 19px;
  font-weight: var(--emu-common-font-weight-regular);
  letter-spacing: .19px;
  color: var(--emu-common-colors-black);
  border: none;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 23px;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav ul li a {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    border-bottom: 2px solid var(--emu-common-colors-transparent);
    letter-spacing: .15px;
    text-align: center;
    font-size: 15px;
  }

  .emu-navigation__content-wrapper nav ul li a:hover {
    border-bottom-color: var(--emu-common-colors-black);
  }
}

.emu-navigation__content-wrapper nav ul li a sup {
  font-size: 70%;
  position: relative;
  top: 3px;
}

.emu-navigation .emu-navigation__item--active a {
  font-weight: var(--emu-common-font-weight-bold);
}

.emu-navigation.header__menu-navlinks--dark {
  background-color: var(--emu-semantic-colors-primary-50);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  border: none;
}

.emu-navigation.header__menu-navlinks--dark a {
  margin-left: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  justify-content: space-between;
  align-items: center;
  padding: 26px 17px 27px;
  display: flex;
}

.emu-navigation.header__menu-navlinks--dark a:hover, .emu-navigation.header__menu-navlinks--dark a:active, .emu-navigation.header__menu-navlinks--dark a:focus, .emu-navigation.header__menu-navlinks--dark a {
  color: var(--emu-common-colors-white);
  border-bottom-color: var(--emu-common-colors-white);
  font-weight: var(--emu-common-font-weight-regular);
}

@media (min-width: 1024px) {
  .emu-navigation.header__menu-navlinks--dark a {
    color: var(--emu-common-colors-black);
  }
}

.emu-navigation.header__menu-navlinks--dark li:last-child a {
  font-weight: var(--emu-semantic-font-weight-500);
  border: none;
}

.dg-instagram__section {
  background-color: var(--emu-common-colors-black);
  padding-top: 27px;
  padding-bottom: 91px;
}

@media (min-width: 1024px) {
  .dg-instagram__section {
    padding-top: 58px;
    padding-bottom: 42px;
  }
}

.dg-instagram__section .aaaem-carousel {
  margin: var(--emu-common-spacing-none);
  margin-left: 27px;
}

@media (min-width: 1024px) {
  .dg-instagram__section .aaaem-carousel {
    margin-left: var(--emu-common-spacing-xl);
    margin-right: var(--emu-common-spacing-xl);
  }
}

.dg-instagram__section .aaaem-carousel .tns-ovh.tns-ah {
  height: auto !important;
}

.dg-instagram__section .aaaem-carousel__action {
  bottom: -35px;
}

.dg-instagram__section .aaaem-carousel__action svg {
  fill: var(--emu-common-colors-white);
}

.dg-instagram__section .aaaem-carousel__action-prev {
  left: -3px;
}

@media (min-width: 1024px) {
  .dg-instagram__section .aaaem-carousel__action-prev {
    right: unset;
    left: -43px;
  }
}

.dg-instagram__section .aaaem-carousel__action-next {
  right: -10px;
}

@media (min-width: 1024px) {
  .dg-instagram__section .aaaem-carousel__action-next {
    right: -43px;
  }
}

.dg-instagram__section .aaaem-embed {
  border-radius: 20px;
  overflow: hidden;
}

.dg-instagram__title .cmp-title__text {
  color: var(--emu-common-colors-white);
  margin-bottom: var(--emu-common-spacing-large);
  font-size: 23px;
  line-height: 35px;
}

@media (min-width: 1024px) {
  .dg-instagram__title .cmp-title__text {
    margin-bottom: 66px;
    font-size: 44px;
    line-height: 66px;
  }
}

.dg-instagram__text.aaaem-text {
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  margin-top: 17px;
}

@media (min-width: 1024px) {
  .dg-instagram__text.aaaem-text {
    margin-top: 24px;
    font-size: 15px;
    line-height: 19px;
  }
}

.dg-instagram__text.aaaem-text a {
  color: inherit;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-wrapper {
  background-color: #0009;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .main-close {
  top: -55px;
  right: 0;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .main-close:hover, [data-component="modal"][data-id="jan-2024-alle-offer"] .main-close:focus, [data-component="modal"][data-id="jan-2024-alle-offer"] .main-close {
  opacity: 1;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .main-close span {
  color: var(--emu-common-colors-white);
  font-family: san-serif;
  font-size: 34px;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  max-width: 367px;
  margin: auto;
  top: calc(50% + 10px);
  transform: translate(0, -50%);
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content {
    top: unset;
    transform: translate(var(--emu-common-spacing-none), var(--emu-common-spacing-none));
    max-width: 921px;
    margin: 15vh auto;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content > div {
  background-color: var(--emu-semantic-colors-secondary-100);
  aspect-ratio: 160 / 253;
  position: relative;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content > div {
    aspect-ratio: 1024 / 597;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .background-image-mobile, [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .background-image {
  width: 100%;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .background-image-mobile {
    display: none;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .background-image {
  display: none;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .background-image {
    display: block;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .lock-up {
  left: var(--emu-common-spacing-none);
  right: 0;
  top: var(--emu-common-spacing-none);
  z-index: 2;
  flex-direction: column;
  height: 100%;
  display: flex;
  position: absolute;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .lock-up {
    right: 50%;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .main-text {
  height: var(--emu-common-sizing-xxs);
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  overflow: hidden;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .button {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .button .aaaem-button {
  padding-bottom: 8px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: var(--emu-common-spacing-small);
  margin-top: 102%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .button .aaaem-button {
    margin-top: 85%;
    padding: 11px 46px;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .button .aaaem-button span {
  font-family: Arial, sans-serif;
  font-size: 17px;
  line-height: 17px;
}

@media (min-width: 1024px) {
  [data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .button .aaaem-button span {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: 20px;
  }
}

[data-component="modal"][data-id="jan-2024-alle-offer"] .modal-content .disclaimer {
  height: var(--emu-common-sizing-xxs);
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  overflow: hidden;
}

.product-card:hover .product-card__img--secondary img {
  opacity: 1;
  transition: opacity .2s ease-in, transform .4s ease-in-out;
  transform: scale(1);
}

.product-card:hover .product-card__cta--primary.emu-button {
  opacity: 1;
  pointer-events: initial;
  background-color: #ffffff80;
  transition: all .2s linear;
  top: 50%;
}

.product-card__main-title {
  display: none;
}

.product-card__img-container {
  position: relative;
  overflow: hidden;
}

.product-card__img--secondary img {
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  z-index: var(--emu-common-other-z-index-layer);
  width: 100%;
  height: 100%;
  transition: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.2);
}

.product-card__img--stamp img {
  z-index: var(--emu-common-other-z-index-base);
  width: 55px;
  height: auto;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

@media (min-width: 1024px) {
  .product-card__img--stamp img {
    width: 103px;
    bottom: 8px;
    right: 8px;
  }
}

.product-card__badge {
  background-color: var(--emu-common-colors-white);
  padding-top: var(--emu-common-spacing-xs);
  padding-left: 15px;
  padding-bottom: var(--emu-common-spacing-xs);
  letter-spacing: -.39px;
  border-radius: 20px;
  padding-right: 15px;
  font-size: 13px;
  position: absolute;
  top: 14px;
  left: 12px;
}

@media (min-width: 1024px) {
  .product-card__badge {
    letter-spacing: -.42px;
    padding: 1px 24px;
    font-size: 14px;
    top: 20px;
    left: 21px;
  }
}

.product-card__cta--primary.emu-button {
  z-index: var(--emu-common-other-z-index-layer);
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 212px;
  max-width: calc(100% - 5px);
  padding-top: 15px;
  padding-bottom: 15px;
  transition: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .product-card__cta--primary.emu-button {
    width: 267px;
    padding: 17px 10px;
  }
}

.product-card__cta--secondary.product-card__cta.emu-button {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-block;
}

.product-card__text-container.aaaem-container.cmp-container {
  padding-top: 18px;
}

@media (min-width: 1024px) {
  .product-card__text-container.aaaem-container.cmp-container {
    padding-top: 23px;
  }
}

.product-card__pretitle .cmp-title__text {
  font-family: var(--emu-common-font-families-sans);
  font-size: 14px;
  font-weight: var(--emu-common-font-weight-bold);
  letter-spacing: .42px;
  line-height: 150%;
}

@media (min-width: 1024px) {
  .product-card__pretitle .cmp-title__text {
    letter-spacing: .48px;
    font-size: 16px;
  }
}

.product-card__title .cmp-title__text {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-weight: var(--emu-common-font-weight-light);
  letter-spacing: -.54px;
  margin-top: var(--emu-common-spacing-xs);
  line-height: 150%;
}

@media (min-width: 1024px) {
  .product-card__title .cmp-title__text {
    letter-spacing: -.6px;
    font-size: 20px;
  }
}

.product-card__text {
  letter-spacing: -.45px;
  font-size: 15px;
  line-height: 130%;
  font-weight: var(--emu-common-font-weight-light);
  margin-top: 15px;
  margin-bottom: 20px;
}

.product-card__text p, .product-card__text li {
  font-weight: inherit;
}

@media (min-width: 1024px) {
  .product-card__text {
    letter-spacing: -.48px;
    font-size: 16px;
  }
}

.product-card__text b {
  letter-spacing: .42px;
}

@media (min-width: 1024px) {
  .product-card__text b {
    letter-spacing: .48px;
  }
}

.cq-Editable-dom--container .product-card__img img, .cq-Editable-dom--container .product-card__cta, .cq-Editable-dom--container .product-card__badge {
  opacity: 1 !important;
  position: static !important;
}

.product-grid {
  padding-top: 34px;
  padding-bottom: 34px;
}

@media (min-width: 1024px) {
  .product-grid {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.product-grid__title .cmp-title__text {
  letter-spacing: .42px;
  font-size: 14px;
  line-height: 1.5;
  font-family: var(--emu-common-font-families-sans);
  margin-bottom: 12px;
  padding-left: 19px;
}

@media (min-width: 1024px) {
  .product-grid__title .cmp-title__text {
    font-size: var(--emu-common-font-sizes-wide-medium);
    letter-spacing: .48px;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 68px;
    padding-right: 68px;
  }
}

@media (min-width: 768px) {
  .product-grid__title .cmp-title__text br {
    display: none;
  }
}

.product-grid__main-grid {
  grid-gap: 14px;
  grid-row-gap: 23px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 23px 14px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

@media (min-width: 1024px) {
  .product-grid__main-grid {
    grid-gap: 19px;
    gap: 19px;
  }
}

@media (min-width: 1440px) {
  .product-grid__main-grid {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-left: 65px;
    margin-right: 65px;
  }
}

.product-grid__main-grid > .container {
  max-width: calc(50% - 10px);
}

.product-grid__item {
  flex-direction: column;
  width: 174px;
  max-width: 100%;
  display: flex;
}

@media (min-width: 768px) {
  .product-grid__item {
    width: 313px;
  }
}

.dg-product-showcase {
  padding-top: 39px;
  padding-bottom: 31px;
}

@media (min-width: 1024px) {
  .dg-product-showcase {
    padding-top: 79px;
  }
}

.dg-product-showcase__title .cmp-title__text {
  letter-spacing: -.8px;
  margin-left: var(--emu-common-spacing-large);
  margin-right: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .dg-product-showcase__title .cmp-title__text {
    letter-spacing: -1.2px;
  }
}

.dg-product-showcase__title .cmp-title__text sup {
  font-size: 60%;
}

.dg-product-showcase__subtitle .cmp-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  letter-spacing: .45px;
  font-weight: var(--emu-common-font-weight-bold);
  font-family: var(--emu-semantic-font-families-body);
  margin: 14px 48px;
}

@media (min-width: 1024px) {
  .dg-product-showcase__subtitle .cmp-title__text {
    letter-spacing: .63px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 21px;
    line-height: 31.5px;
  }
}

.dg-product-showcase__text[data-component="text"] {
  margin-left: 48px;
  margin-right: 48px;
}

@media (min-width: 1024px) {
  .dg-product-showcase__text[data-component="text"] {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.dg-product-showcase__text[data-component="text"] p {
  letter-spacing: -.48px;
}

@media (min-width: 1024px) {
  .dg-product-showcase__text[data-component="text"] p {
    letter-spacing: -.72px;
  }
}

.dg-product-showcase__cta[data-component="button"] {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  letter-spacing: 3.6px;
  padding-top: 11.5px;
  padding-bottom: 11.5px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  min-width: 228px;
  margin-top: 22px;
}

@media (min-width: 1024px) {
  .dg-product-showcase__cta[data-component="button"] {
    min-width: 239px;
    margin-top: 34px;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
  }
}

.dg-product-showcase .product-card__img--stamp img {
  width: 59px;
  bottom: 20px;
  right: 24px;
}

@media (min-width: 1024px) {
  .dg-product-showcase .product-card__img--stamp img {
    width: 76px;
  }
}

.dg-product-showcase .product-card__img--stamp-big img {
  width: 87px;
  bottom: 10px;
  right: 16px;
}

@media (min-width: 1024px) {
  .dg-product-showcase .product-card__img--stamp-big img {
    width: 111px;
  }
}

.dg-product-showcase .product-card__title .cmp-title__text {
  margin-top: var(--emu-common-spacing-xxs);
}

.dg-product-showcase .product-card__text {
  margin-top: var(--emu-common-spacing-small);
  max-width: 274px;
  margin-left: auto;
  margin-right: auto;
}

.aaaem-teaser .content-container {
  position: absolute;
  top: 0;
}

.aaaem-teaser__title.cmp-teaser__title {
  font-size: 50px;
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  letter-spacing: -1.5px;
  text-shadow: var(--emu-common-spacing-none) var(--emu-common-spacing-xs) var(--emu-common-spacing-xs) #00000040;
  padding-top: var(--emu-common-spacing-medium);
  padding-left: 18px;
  padding-right: 18px;
}

@media (min-width: 1024px) {
  .aaaem-teaser__title.cmp-teaser__title {
    letter-spacing: -2.4px;
    padding-bottom: 24px;
    font-size: 80px;
    line-height: 80px;
  }
}

.aaaem-teaser__title.cmp-teaser__title bdi {
  display: inline-block;
}

.aaaem-teaser .disclaimer {
  bottom: var(--emu-common-spacing-medium);
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: 28px;
}

@media (min-width: 1024px) {
  .aaaem-teaser .disclaimer {
    bottom: 19px;
    left: 26px;
  }
}

.aaaem-teaser .disclaimer p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: -.36px;
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .aaaem-teaser .disclaimer p {
    letter-spacing: -.42px;
    font-size: 14px;
    line-height: 21px;
  }
}

.aaaem-teaser .teaser-image-container .cmp-teaser__image--mobile {
  display: block;
}

@media (min-width: 1024px) {
  .aaaem-teaser .teaser-image-container .cmp-teaser__image--mobile {
    display: none;
  }
}

.aaaem-teaser .teaser-image-container .cmp-teaser__image--desktop {
  display: none;
}

@media (min-width: 1024px) {
  .aaaem-teaser .teaser-image-container .cmp-teaser__image--desktop {
    display: block;
  }
}

.aaaem-teaser .teaser-image-container .cmp-teaser__image img {
  width: 100%;
  height: auto;
}

.dg-testimonial__container {
  flex-direction: column;
  justify-content: space-between;
  padding: 47px 31px 46px;
  display: flex;
}

@media (min-width: 1024px) {
  .dg-testimonial__container {
    padding: var(--emu-common-spacing-none);
    padding-top: 43px;
    padding-bottom: 46px;
  }
}

.dg-testimonial__title .cmp-title__text {
  letter-spacing: -1.2px;
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 50px;
}

@media (min-width: 1024px) {
  .dg-testimonial__title .cmp-title__text {
    letter-spacing: -1.29px;
    margin-top: 9px;
    margin-bottom: 22px;
    font-size: 43px;
    line-height: 65px;
  }
}

.dg-testimonial__sub-title .cmp-title__text {
  font-family: var(--emu-common-font-families-sans);
}

.dg-testimonial__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-common-line-heights-narrow-medium);
}

@media (min-width: 1024px) {
  .dg-testimonial__text {
    font-size: 17px;
    line-height: 26px;
  }

  .dg-testimonial__text br {
    display: none;
  }
}

.dg-testimonial__text sup {
  font-size: 65%;
}

[data-component="text"], [data-component="title"] {
  color: var(--emu-common-colors-black);
}

[data-component="text"] p, [data-component="text"] li, [data-component="title"] p, [data-component="title"] li {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin: var(--emu-common-spacing-none);
}

.video-text-container__wrapper {
  grid-gap: 27px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
  padding-bottom: 37px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

@media (min-width: 1024px) {
  .video-text-container__wrapper {
    flex-direction: row;
    padding-bottom: 34px;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 1440px) {
  .video-text-container__wrapper {
    grid-gap: 130px;
    justify-content: center;
    gap: 130px;
  }
}

.video-text-container__wrapper > div:first-child {
  width: 345px;
  max-width: 100%;
}

@media (min-width: 1024px) {
  .video-text-container__wrapper > div:first-child {
    width: 636px;
    max-width: 50%;
  }
}

.video-text-container__video-content {
  grid-gap: 7px;
  flex-direction: column;
  gap: 7px;
  display: flex;
}

.video-text-container__video-content .embed {
  background-color: var(--emu-semantic-colors-secondary-100);
  padding: 15px;
}

@media (min-width: 1024px) {
  .video-text-container__video-content .embed {
    padding: 20px;
  }
}

.video-text-container__disclaimer-text {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-common-line-heights-narrow-medium);
  letter-spacing: -.36px;
}

@media (min-width: 1024px) {
  .video-text-container__disclaimer-text {
    letter-spacing: -.42px;
    font-size: 14px;
    line-height: 21px;
  }
}

.video-text-container__text-content {
  grid-gap: 31px;
  flex-direction: column;
  gap: 31px;
  width: 100%;
  display: flex;
}

@media (min-width: 1024px) {
  .video-text-container__text-content {
    grid-gap: 48px;
    gap: 48px;
    width: 518px;
    max-width: 100%;
  }
}

.video-text-container__text-content h2 {
  letter-spacing: -.8px;
  margin-bottom: 26px;
}

@media (min-width: 1024px) {
  .video-text-container__text-content h2 {
    letter-spacing: -1.2px;
    margin-bottom: 48px;
  }
}

.video-text-container__text-content [data-component="text"] p {
  letter-spacing: -.48px;
}

@media (min-width: 1024px) {
  .video-text-container__text-content [data-component="text"] p {
    letter-spacing: -.72px;
  }
}

.video-text-container__cta {
  min-width: 228px;
}

@media (min-width: 1024px) {
  .video-text-container__cta {
    min-width: 271px;
  }
}

/*# sourceMappingURL=main.css.map */
