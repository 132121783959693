.product-card {
  &:hover {
    .product-card {
      &__img {
        &--secondary img {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.2s ease-in, transform 0.4s ease-in-out;
        }
      }

      &__cta {
        &--primary.emu-button {
          opacity: 1;
          transition: all 0.2s linear;
          pointer-events: initial;
          top: 50%;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  &__main-title {
    display: none;
  }

  &__img-container {
    position: relative;
    overflow: hidden;
  }

  &__img {
    &--secondary {
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: none;
        transform: scale(1.2);
        z-index: var(--emu-common-other-z-index-layer);
      }
    }

    &--stamp {
      img {
        position: absolute;
        width: 55px;
        height: auto;
        bottom: 6px;
        right: 6px;
        z-index: var(--emu-common-other-z-index-base);

        @include mq('large') {
          width: 103px;
          bottom: 8px;
          right: 8px;
        }
      }
    }
  }

  &__badge {
    background-color: var(--emu-common-colors-white);
    border-radius: 20px;
    position: absolute;
    top: 14px;
    left: 12px;
    padding-top: var(--emu-common-spacing-xs);
    padding-left: 15px;
    padding-bottom: var(--emu-common-spacing-xs);
    padding-right: 15px;
    font-size: 13px;
    letter-spacing: -0.39px;

    @include mq('large') {
      font-size: 14px;
      top: 20px;
      left: 21px;
      padding-top: 1px;
      padding-right: 24px;
      padding-bottom: 1px;
      padding-left: 24px;
      letter-spacing: -0.42px;
    }
  }

  &__cta {
    &--primary.emu-button {
      position: absolute;
      top: 40%;
      left: 50%;
      z-index: var(--emu-common-other-z-index-layer);
      transform: translate(-50%, -50%);
      opacity: 0;
      pointer-events: none;
      transition: none;
      width: 212px;
      align-items: center;
      justify-content: center;
      max-width: calc(100% - 5px);
      padding-top: 15px;
      padding-bottom: 15px;

      @include mq('large') {
        width: 267px;
        padding-top: 17px;
        padding-left: 10px;
        padding-bottom: 17px;
        padding-right: 10px;
      }
    }

    &--secondary {
      // specificity needed to override the default button styles from aaaem-common
      &.product-card__cta.emu-button {
        width: 100%;
        display: inline-block;
        align-items: center;
        justify-content: center;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }
    }
  }

  // specificity needed to override the default styles from card component
  &__text-container.aaaem-container.cmp-container {
    padding-top: 18px;

    @include mq('large') {
      padding-top: 23px;
    }
  }

  &__pretitle {
    .cmp-title__text {
      font-family: var(--emu-common-font-families-sans);
      font-size: 14px;
      font-weight: var(--emu-common-font-weight-bold);
      line-height: 150%;
      letter-spacing: 0.42px;

      @include mq('large') {
        font-size: 16px;
        letter-spacing: 0.48px;
      }
    }
  }

  &__title {
    .cmp-title__text {
      font-family: var(--emu-semantic-font-families-heading);
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      font-weight: var(--emu-common-font-weight-light);
      line-height: 150%;
      letter-spacing: -0.54px;
      margin-top: var(--emu-common-spacing-xs);

      @include mq('large') {
        font-size: 20px;
        letter-spacing: -0.6px;
      }
    }
  }

  &__text {
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.45px;
    margin-bottom: 20px;
    margin-top: 15px;
    font-weight: var(--emu-common-font-weight-light);

    p,
    li {
      font-weight: inherit;
    }

    @include mq('large') {
      font-size: 16px;
      letter-spacing: -0.48px;
    }

    b {
      letter-spacing: 0.42px;

      @include mq('large') {
        letter-spacing: 0.48px;
      }
    }
  }
}

// author styles
.product-card {
  &__img img,
  &__cta,
  &__badge {
    // author view styles
    @include aem-editor-view {
      position: static !important;
      opacity: 1 !important;
    }
  }
}
