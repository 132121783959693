#proven-results {
  .dg {
    &-tabs {
      &__section {
        margin-right: 12px;
        margin-bottom: 37px;
        margin-left: 12px;

        // Needed this breakpoint to make the styles look better.
        @include mq('880px') {
          max-width: 851px; // As per the design.
          margin-right: auto;
          margin-left: auto;
        }

        @include mq('large') {
          max-width: 1093px;
          margin-bottom: 138px;
          padding-left: 12px;
          padding-right: 12px;
        }

        @include mq('x-large') {
          padding: var(--emu-common-spacing-none);
          margin-left: var(--emu-common-spacing-xl);
        }

        @include mq('xx-large') {
          margin-left: auto;
        }
      }

      &__title {
        letter-spacing: -0.8px; // to match the design.
        margin-top: 53px;
        margin-bottom: 40px;

        @include mq('large') {
          letter-spacing: -1.2x;
          margin-top: 56px;
          margin-bottom: 66px;
        }
      }

      &__nav {
        &-text,
        &-links {
          @include mq('large') {
            font-size: 18px;
            letter-spacing: 1.2px; // to match the design.
          }
        }

        &-text {
          letter-spacing: 1.6px; // to match the design.
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            letter-spacing: 1.8px;
            line-height: 27px;
          }

          br {
            display: none;
            @include mq('large') {
              display: block;
            }
          }
        }

        &-links {
          margin-bottom: var(--emu-common-spacing-medium);
          line-height: 16px;

          @include mq('large') {
            line-height: 20px;
          }

          .emphasis {
            display: none;
          }

          a {
            color: inherit;
            text-decoration: none;
            cursor: pointer;
          }

          &.nav--active {
            color: var(--emu-semantic-colors-primary-50);

            .emphasis {
              color: var(--emu-common-colors-black);
              display: block;
            }

            a {
              font-weight: var(--emu-common-font-weight-bold);
            }
          }
        }

        &-section {
          @include mq('large') {
            max-width: 199px; // As per the design.
          }
        }
      }

      &__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: var(--emu-semantic-line-heights-narrow-xs);
        max-width: 815px; // As per the design.
        padding-left: var(--emu-common-spacing-medium);
        padding-right: var(--emu-common-spacing-medium);
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          padding: var(--emu-common-spacing-none);
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 20px;
        }
      }

      &__content {
        @include mq('large') {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        @include mq('xx-large') {
          position: relative;
          right: 10%; // to make the alignment look better in large screens.
        }
      }

      &__ba {
        &-section {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 11px;
          margin-top: 5px;
          margin-bottom: 28px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }

          @include mq('x-large') {
            gap: 23px;
          }

          &-wrapper {
            display: none;

            &.tab--active {
              display: block;
            }
          }
        }

        &-text {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: 1.2px; // As per the design.
          margin-top: 10px;

          @include mq('large') {
            font-size: 15px;
            line-height: 23px;
            letter-spacing: 1.5px;
            margin-top: 21px;
          }

          .emphasis {
            display: inline-block;
          }
        }
      }
    }

    &-results {
      &__section {
        position: relative;
      }

      &__content {
        padding-top: 38px;
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: 43px;
        padding-left: var(--emu-common-spacing-small);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq('large') {
          padding-top: 25px;
        }
      }

      &__title {
        margin-bottom: 42px;

        @include mq('large') {
          margin-bottom: 13px;
        }

        br {
          @include mq('large') {
            display: none;
          }
        }

        .cmp-title__text {
          @include mq('large') {
            line-height: 90px;
          }
        }
      }

      &__sub-text {
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -0.45px; // As per the design.
        margin-bottom: 24px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-medium);
          letter-spacing: -0.57px;
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }

      &__text {
        font-size: var(--emu-common-line-heights-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        letter-spacing: -0.54px; // As per the design.
        margin-bottom: 43px;
        max-width: 500px; // to match the design.
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font-size: 38px;
          line-height: var(--emu-common-line-heights-wide-xl);
          letter-spacing: -1.14px;
          max-width: 952px; // to match the design.
        }

        p {
          font-family: var(--emu-semantic-font-families-heading);
          font-weight: var(--emu-common-font-weight-light);
        }
      }

      &__percentage {
        margin-bottom: 13px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }

        .cmp-title__text {
          font-size: 55px;
          line-height: 55px;
          letter-spacing: -1.65px; // As per the design.

          @include mq('large') {
            font-size: 88px;
            line-height: 88px;
            letter-spacing: -2.64px;
          }
        }

        &-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 25px;

          @include mq('large') {
            gap: 70px;
          }

          .dg-results__text {
            max-width: 166px; // As per the design.
            margin-left: auto;
            margin-right: auto;

            @include mq('large') {
              font-size: 27px;
              line-height: 33px;
              letter-spacing: -0.81px;
              max-width: 323px;
              margin-bottom: 46px;
            }

            br {
              @include mq('large') {
                display: none;
              }
            }

            .emphasis {
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.42px; // As per the design.

              @include mq('large') {
                font-size: var(--emu-semantic-font-sizes-wide-medium);
                line-height: 23px;
                letter-spacing: -0.57px;
              }
            }
          }
        }
      }

      &__small-text {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: var(--emu-semantic-line-heights-narrow-xs);
        letter-spacing: -0.36px; // As per the design.

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-large);
          line-height: 25px;
          letter-spacing: -0.6px;
          margin-top: 21px;
        }
      }
    }

    &-satisfaction {
      &__section {
        position: relative;

        .dg-results {
          &__title {
            margin-bottom: 63px;

            @include mq('large') {
              margin-bottom: 69px;
            }
          }

          &__sub-text {
            margin-bottom: var(--emu-common-spacing-large);

            @include mq('large') {
              margin-bottom: 24px;
              min-height: 58px;
            }
          }

          &__small-text {
            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-medium);
              line-height: var(--emu-semantic-line-heights-wide-medium);
              letter-spacing: -0.57px; // As per the design.
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 68px;

        @include mq('large') {
          flex-direction: row;
          gap: var(--emu-common-spacing-none);
          max-width: 1088px; // As per the design.
          margin-left: auto;
          margin-right: auto;
        }

        > .container {
          @include mq('large') {
            flex: 1;
          }

          &:first-child {
            .dg-satisfaction__inner-section {
              position: relative;

              &::after {
                content: '';
                height: 1px;
                background-color: var(--emu-common-colors-black);
                display: block;
                position: absolute;
                bottom: -39px;
                left: 24px;
                width: calc(100% - 48px); // to match the design.

                @include mq('large') {
                  bottom: unset;
                  left: unset;
                  top: 0px;
                  height: 100%;
                  width: 1px;
                  right: -21px;
                }
              }
            }
          }
        }

        &-wrapper {
          padding-top: 38px;
          padding-right: var(--emu-common-spacing-small);
          padding-bottom: 52px;
          padding-left: var(--emu-common-spacing-small);
          display: flex;
          flex-direction: column;
          justify-content: center;

          @include mq('large') {
            padding: var(--emu-common-spacing-none);
            padding-top: 42px;
            padding-bottom: 80px;
          }
        }
      }

      &__main-text {
        font-size: 28px;
        line-height: 28px;
        letter-spacing: -0.84px; // As per the design.
        margin-bottom: 25px;

        @include mq('large') {
          font-size: 66px;
          line-height: 66px;
          letter-spacing: -1.98px;
          margin-bottom: 50px;
        }

        p {
          font-weight: var(--emu-common-font-weight-light);
          font-family: var(--emu-semantic-font-families-heading);
        }

        .emphasis {
          font-size: 55px;
          line-height: 55px;
          letter-spacing: -1.65px; // As per the design.

          @include mq('large') {
            font-size: 88px;
            line-height: 88px;
            letter-spacing: -2.64px;
          }
        }
      }

      &__text {
        font-size: var(--emu-common-line-heights-narrow-medium);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        letter-spacing: -0.54px; // As per the design.
        margin-bottom: 25px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-semantic-line-heights-wide-small);
          letter-spacing: -0.72px;
          margin-bottom: 38px;
        }
      }
    }

    &-results__section,
    &-satisfaction__section {
      .aaaem-teaser {
        position: static;

        &__image {
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: var(--emu-common-other-z-index-behind);
          }
        }
      }
    }
  }
}
