/** 
 * The find a provider component displays the find a provider teaser with cta across multiple pages. The find a provider component has two variants.  
 * First variant - It is present in treatment , customized for you and faq pages.
 * Design - https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-355&mode=design&t=PTMmHONYBh4LWAR1-4
 
 * Second variant - It is present in home , proven results and skm take home pages.
 * Design - https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-208&mode=design&t=PTMmHONYBh4LWAR1-4
 */

.dg {
  &-fap__teaser {
    margin-left: auto;
    margin-right: auto;

    @include mq('large') {
      margin-left: 70px;
      margin-right: 70px;
      margin-top: 65px;
      margin-bottom: 65px;
    }

    &--small {
      margin-left: 25px;
      margin-right: 25px;
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-left: 65px;
        margin-right: 65px;
        margin-top: 65px;
        margin-bottom: 65px;
      }

      .cmp-teaser__image--mobile img {
        height: 299px;
        width: 100%;
        object-fit: cover;
      }
    }

    .cmp-teaser__image {
      img {
        min-height: 220px;
        object-fit: cover;
      }
    }

    .content-container {
      width: 100%;
      padding: var(--emu-common-spacing-none);
      padding-top: 44px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(165, 127, 116, 0.75) 20%,
        rgba(165, 127, 116, 0.23) 53.86%,
        rgba(165, 127, 116, 0) 95.31%
      ); // As per the design.

      @include mq('x-large') {
        padding-top: 54px;
      }

      .aaaem-teaser {
        &__title {
          font: var(--emu-component-ingredients-title-h2-typography-narrow);
          margin-bottom: 37px;
          letter-spacing: -0.8px; // As per the design.
          padding: var(--emu-common-spacing-none);
          text-shadow: none;

          @include mq('large') {
            letter-spacing: -1.2px;
            font: var(--emu-component-ingredients-title-h2-typography-wide);
          }

          br {
            @include mq('large') {
              display: none;
            }
          }
        }

        &__action {
          &-container {
            margin-top: var(--emu-common-spacing-none);
          }
          &-link {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
            letter-spacing: 3.6px; // As per the design.
            padding-top: 12.5px;
            padding-right: 41px;
            padding-bottom: 12.5px;
            padding-left: 41px;
            background-color: var(--emu-common-colors-white);
            color: var(--emu-semantic-colors-primary-50);
            text-decoration: none;
            font-weight: var(--emu-common-font-weight-bold);
            display: inline-block;

            &:hover,
            &:active {
              background-color: var(--emu-semantic-colors-primary-50);
              color: var(--emu-common-colors-white);
            }

            @include mq('large') {
              padding-top: 16.5px;
              padding-right: 62px;
              padding-bottom: 16.5px;
              padding-left: 62px;
            }
          }
        }

        &__action-link {
        }
      }

      .title-lockup {
        max-width: 100%;
      }

      .disclaimer {
        left: 19px;
        bottom: 23px;

        @include mq('large') {
          left: var(--emu-common-sizing-small);
          bottom: 14px;
        }

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: var(--emu-common-line-heights-narrow-medium);
          letter-spacing: -0.36px;
          margin: var(--emu-common-spacing-none);

          @include mq('large') {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.42px;
          }
        }
      }
    }
  }
}
