/** 
 * This is a testimonial component which displays testimonial of patients. It is present in home , treatment and customized for you page.
 * Design link : https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-210&mode=design&t=PTMmHONYBh4LWAR1-4
 */

.dg {
  &-testimonial {
    &__container {
      padding-top: 47px;
      padding-right: 31px;
      padding-bottom: 46px;
      padding-left: 31px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mq('large') {
        padding: var(--emu-common-spacing-none);
        padding-top: 43px;
        padding-bottom: 46px;
      }
    }

    &__title {
      .cmp-title__text {
        line-height: 50px;
        letter-spacing: -1.2px; // As per the design.
        margin-top: 50px;
        margin-bottom: 50px;

        @include mq('large') {
          font-size: 43px;
          line-height: 65px;
          letter-spacing: -1.29px; // as per figma
          margin-top: 9px;
          margin-bottom: 22px;
        }
      }
    }

    &__sub-title {
      .cmp-title__text {
        font-family: var(--emu-common-font-families-sans);
      }
    }

    &__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: var(--emu-common-line-heights-narrow-medium);

      @include mq('large') {
        font-size: 17px;
        line-height: 26px;
      }

      br {
        @include mq('large') {
          display: none;
        }
      }

      sup {
        font-size: 65%;
      }
    }
  }
}
