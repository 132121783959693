.header {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--emu-common-colors-white);
  transition: all 0.3s linear;

  .cmp-container-header {
    max-width: var(--emu-component-containers-container-max-width);
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    @include mq('large') {
      display: block;
      height: auto;
    }
  }

  // styles for for professionals section and button on top of the header section
  &__for-professionals {
    background-color: var(--emu-semantic-colors-primary-50);
    display: flex;
    justify-content: right;
    padding-right: 23px; // to match design

    .button {
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
      height: 40px; // to match design
      display: flex;
      align-items: center;

      a {
        padding: var(--emu-common-spacing-none);
        text-decoration: none;
        display: flex;
        gap: 5px;

        &:hover {
          color: var(--emu-common-colors-white);
        }
      }

      .cmp-button__text {
        font-size: 14px;
        font-weight: var(--emu-semantic-font-weight-500);
      }
    }
  }

  // styles for menu navigation section in header section
  &__nav-container {
    position: fixed;
    left: 0;
    top: 81px; // 81px is the height of the header band
    display: flex;
    max-height: calc(100% - 81px); // 81px is the height of the header band
    flex-direction: column;
    width: 100%;
    transform: translateX(-100%);
    opacity: 0.5;
    overflow: auto;
    transition: all 0.3s ease-out;
    background-color: var(--emu-common-colors-white);

    @include mq('large') {
      height: auto;
      opacity: 1;
      transform: none;
      position: relative;
      left: unset;
      top: 0;
      padding-right: 25px;
      padding-left: 25px;
      align-items: center;
    }

    .image {
      display: flex;
      align-items: center;
    }
  }

  &__nav-container > div:first-child {
    width: 100%;
  }

  &__nav-logo-container {
    display: flex;
    justify-content: space-between;
  }

  &__nav-logo-container > div:first-child {
    width: 42%; // to match design
    margin-right: var(--emu-common-spacing-large);
  }

  &__nav-logo-container > div:last-child {
    width: 44%; // to match design

    .emu-navigation__menu--level-0 li:nth-child(2) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -13px;
        width: var(--emu-common-border-width-thin);
        background-color: var(--emu-semantic-colors-tertiary-50);
        height: 23px;
        margin: auto;
      }
    }

    .emu-navigation__menu--level-0 li:nth-child(3) {
      opacity: 0.75;

      a {
        &:hover,
        &:active {
          color: var(--emu-semantic-colors-primary-50);
          border-bottom-color: var(--emu-semantic-colors-none);
        }
      }

      &.emu-navigation__item--active {
        a {
          font-weight: var(--emu-common-font-weight-regular);
        }
      }
    }

    .emu-navigation__menu--level-0 li:last-child {
      background-color: var(--emu-semantic-colors-primary-50);

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }

      a {
        margin: var(--emu-common-spacing-none);
        padding-top: 6.5px;
        padding-right: 10px;
        padding-bottom: 6.5px;
        padding-left: 10px;
        color: var(--emu-common-colors-white);
        letter-spacing: -0.45px;
        line-height: 23px;
        font-weight: var(--emu-semantic-font-weight-500);
        display: flex;
        justify-content: center;
        min-width: 134px;

        &:hover {
          border-bottom-color: var(--emu-semantic-colors-none);
        }
      }

      &.emu-navigation__item--active {
        a {
          font-weight: var(--emu-semantic-font-weight-500);
        }
      }
    }
  }

  &__logo {
    margin: auto;

    .aaaem-image {
      position: relative;
      right: 25px; //to match design
      width: 96px; //to match design
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__global-navigation {
    display: none;
  }

  &.show-mobile-menu {
    .header__menu-close {
      &.aaaem-button {
        display: block;

        @include mq('large') {
          display: none;
        }
      }
    }

    .header__menu-trigger {
      &.aaaem-button {
        display: none;
      }
    }

    .header {
      background-color: var(--emu-common-colors-white);
    }

    .header__nav-container {
      transition: all 0.3s ease-in;
      opacity: 1;
      transform: translateX(0);

      @include mq('large') {
        transform: none;
      }
    }
  }

  &__content {
    .button {
      button {
        -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

        &.header__menu-trigger {
          height: 81px; //to match design
          width: 50px; //to match design
          padding: var(--emu-common-spacing-none);
          background-color: transparent;
          border: none;
          display: block;
          padding-left: 5px;

          @include mq('large') {
            display: none;
          }
        }

        &.header__menu-close {
          height: 81px; //to match design
          width: 50px; //to match design
          padding: var(--emu-common-spacing-none);
          background-color: transparent;
          border: none;
          padding-left: 10px;
          display: none;
        }
      }
    }
  }
}

.sticky {
  @include mq('large') {
    transform: translateY(-40px);
  }
}
