#skm-take-home {
  .product-grid-container {
    &__intro-text {
      max-width: 747px; //as per design
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px; //as per design
      padding-right: 30px; //as per design
      margin-top: 50px; //as per design
      margin-bottom: 50px; //as per design

      @include mq('large') {
        margin-top: 53px; //as per design
        margin-bottom: 39px; //as per design
      }

      h3,
      h2,
      h4,
      h5 {
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: 30px; //as per design
        letter-spacing: -0.72px; //as per design

        @include mq('large') {
          font-size: 42px; //as per design
          line-height: 52.5px; //as per design
          letter-spacing: -1.26px; //as per design
        }
      }
    }

    &__title {
      padding-left: 48px; //as per design
      padding-right: 48px; //as per design
      padding-top: 37px; //as per design
      padding-bottom: 15px; //as per design

      @include mq('large') {
        padding-left: var(--emu-common-spacing-xl);
        padding-right: var(--emu-common-spacing-xl);
        padding-top: 41px; //as per design
        padding-bottom: 22px; //as per design
      }

      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-50);
        font-family: var(--emu-semantic-font-families-body);
        letter-spacing: 0.54px; //as per design

        @include mq('large') {
          font-size: 21px; //as per design
          line-height: 31.5px; //as per design
          letter-spacing: 2.1px; //as per design
        }
      }
    }

    &__description {
      padding-bottom: 12px; //as per design
      margin-left: auto;
      margin-right: auto;
      max-width: 520px; //as per design
      padding-left: 44px; //as per design
      padding-right: 44px; //as per design

      @include mq('large') {
        max-width: 650px; //as per design
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      p {
        letter-spacing: -0.48px; //as per design

        @include mq('large') {
          letter-spacing: -0.72px; //as per design
        }
      }
    }

    .product-grid {
      &--space-large {
        padding-top: 66px; //as per design
        padding-bottom: 66px; //as per design

        @include mq('large') {
          padding-top: 40px; //as per design
          padding-bottom: 40px; //as per design
        }
      }

      &__item {
        height: 100%;

        &--solo-image {
          img {
            min-height: 389px; //as per design
            object-fit: cover;
            max-height: 389px;

            @include mq('medium') {
              max-height: 608px; //as per design
              height: auto;
            }
          }

          .container,
          .aaaem-container,
          .image,
          .aaaem-image,
          img {
            height: 100%;
          }
        }

        // transition styles for solo image
        &-image {
          transition: all 0.6s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }

          &-container {
            overflow: hidden;
          }
        }

        // to maintain constant height with irregular text content
        > .container {
          height: 100%;
        }

        .card a {
          display: inline-block;
          height: 100%;
        }
      }
    }

    .product-card {
      // to maintain constant height with irregular text content
      &,
      .aaaem-card__wrapper,
      .aaaem-card__content-wrapper,
      .aaaem-card__footer {
        height: 100%;
      }

      div[data-ref='card-content'],
      &__text-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &__text-container {
        > .text {
          flex-grow: 1;
        }
      }

      // transition styles for product images
      &:hover {
        .product-card {
          &__img {
            &--primary img {
              transform: scale(1.2);
            }
          }
        }
      }

      &__title {
        .cmp-title__text {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-xxs);
          font-size: var(
            --emu-semantic-font-sizes-narrow-small
          ); //as per design
          line-height: 1.25; //as per design
          letter-spacing: -0.48px; //as per design

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-large);
            letter-spacing: -0.6px; //as per design
          }
        }

        &--line-height-small {
          .cmp-title__text {
            line-height: 17.6px; //as per design

            @include mq('large') {
              line-height: var(--emu-semantic-line-heights-wide-large);
            }
          }
        }
      }

      &__text-container.aaaem-container.cmp-container {
        padding-top: 13px; //as per design

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
        }
      }

      &__text-container--padding-sm.aaaem-container.cmp-container {
        padding-top: var(--emu-common-spacing-small);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
        }
      }

      &__text {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-medium);
        font-size: 14px; //as per design
        line-height: 21px; //as per design
        font-weight: var(--emu-common-font-weight-regular);
        letter-spacing: -0.42px; //as per design

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-medium);
          letter-spacing: -0.48px; //as per design
        }

        &--margin-xxs {
          margin-bottom: var(--emu-common-spacing-xxs); //as per design
        }

        &--margin-sm {
          margin-bottom: var(--emu-common-spacing-small);
        }

        &--margin-xxs,
        &--margin-sm {
          @include mq('medium') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }
      }

      &__img {
        overflow: hidden;

        &--primary img {
          transition: all 0.6s ease-in-out;
          height: 229px; //as per design

          @include mq('medium') {
            height: 412px; //as per design
          }
          @include mq('large') {
            height: 438px; //as per design
          }
        }

        &--stamp-small img {
          bottom: 10px; //as per design
          right: 10px; //as per design
          width: 44px; //as per design

          @include mq('large') {
            bottom: 15px; //as per design
            right: 15px; //as per design
            width: 76px; //as per design
          }
        }
      }
    }
  }
}
