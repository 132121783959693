#for-professionals {
  .cmp-teaser__title {
    padding-top: var(--emu-common-spacing-xxs);

    @include mq('large') {
      margin-top: -20px;
    }
  }

  .video-text-container {
    &__wrapper {
      padding-top: 112px;

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 51px;
        padding-left: 63px;
        gap: 124px;
        justify-content: flex-start;
      }
    }

    &__text-content {
      max-width: 338px; // as per figma

      @include mq('large') {
        width: 530px; // as per figma
        max-width: 100%;
        margin-bottom: var(--emu-common-spacing-small);
      }

      h2 {
        margin-bottom: 35px;

        @include mq('large') {
          max-width: 497px; // added here to match figma spacings
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 56px;
        }
      }

      p {
        letter-spacing: -0.48px;

        @include mq('large') {
          letter-spacing: normal;
        }
      }
    }

    &__video-content {
      gap: 13px;
    }
  }

  .for-professionals {
    &-brandbox {
      background-color: var(--emu-semantic-colors-primary-100);
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 46px;

      @include mq('large') {
        flex-direction: row;
        gap: 109px;
        padding-top: 37px;
        padding-bottom: 45px;
        padding-left: 109px;
        padding-right: 88px;
      }

      @include mq('xx-large') {
        align-items: normal;
        justify-content: center;
      }

      &__content {
        @include mq('xx-large') {
          margin-top: 83px;
        }
      }

      > .container:last-child {
        @include mq('large') {
          flex: 1;
          max-width: 558px;
        }
      }

      &__logo {
        &--mobile {
          display: flex;
          justify-content: center;

          @include mq('large') {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include mq('large') {
            display: flex;
            justify-content: center;
          }
        }
      }

      &__text {
        max-width: 301px; // as per figma
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 31px;
        margin-top: 19px;

        @include mq('large') {
          max-width: 369px; // as per figma
          margin-bottom: 40px;
          margin-top: -7px;
        }

        p {
          letter-spacing: -0.48px;
          color: var(--emu-common-colors-white);

          @include mq('large') {
            letter-spacing: -0.72px;
          }
        }
      }

      &__cta {
        background-color: var(--emu-semantic-colors-primary-200);
        color: var(--emu-common-colors-white);
        border: none;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 68px;
        padding-right: 68px;

        &:hover,
        &:active {
          color: var(--emu-common-colors-white);
          background-color: var(--emu-semantic-colors-primary-50);
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
          padding-bottom: 17px;
          padding-left: 56px;
          padding-right: 56px;
        }
      }

      &__teaser-wrapper {
        .aaaem-teaser {
          .teaser-image-container {
            max-width: 342px;

            @include mq('large') {
              max-width: 558px;
            }
          }
        }
      }
    }

    &-discover-alle {
      padding-top: 27px;
      padding-bottom: 40px;
      position: relative;

      @include mq('large') {
        padding-top: 60px;
        padding-bottom: 36px;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
      }

      @include mq('xx-large') {
        max-width: 1310px; // as per figma
      }

      &__image-wrapper {
        display: flex;
        justify-content: center;
      }

      &__background-image {
        &--mobile {
          display: block;

          @include mq('large') {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include mq('large') {
            display: block;
          }

          img {
            width: 1310px; // as per figma
          }
        }
      }

      &__content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 76px;
        width: 100%;

        @include mq('large') {
          top: 100px;
          max-width: 50%;
          margin-left: 44px;
        }

        @include mq('xx-large') {
          top: 114px;
          max-width: 737px; // as per figma
          margin-left: 88px;
        }
      }

      &__text {
        margin-top: 33px;
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        @include mq('xx-large') {
          margin-top: 41px;
          margin-bottom: 42px;
        }

        p {
          font-family: var(--emu-semantic-font-families-heading);
          // Minimum font-size to subtract from the original figma value to keep the content span 2-3 lines
          --figma-design-font-diff: 4.5px;
          --font-size: 24px;
          font-size: 20px;
          font-weight: var(--emu-common-font-weight-light);
          line-height: 1.25;
          letter-spacing: -0.72px;
          max-width: 301px; // as per figma

          @include mq('large') {
            font-style: 25px;
            letter-spacing: -1.26px;
            max-width: none;
          }

          @include mq('xx-large') {
            font-size: 37px;
          }
        }
      }

      &__cta {
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 39px;
        padding-right: 39px;
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-primary-50);

        &:hover,
        &:active {
          background-color: var(--emu-semantic-colors-primary-50);
          color: var(--emu-common-colors-white);
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-medium);
          padding-left: 29px;
          padding-right: 29px;
        }
      }

      &__contact-us-text {
        margin-top: 42px;
        max-width: 352px; // as per figma
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-large);
          max-width: 910px;
        }

        @include mq('xx-large') {
          max-width: 1158px; // as per figma
        }

        p {
          font-family: var(--emu-semantic-font-families-heading);
          font-size: 24px;
          font-weight: var(--emu-common-font-weight-light);
          line-height: 1.25;
          letter-spacing: -0.72px;

          @include mq('large') {
            font-size: 42px;
            letter-spacing: -1.26px;
            padding-top: 26px;
            padding-bottom: 26px;
          }

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
}
