.aaaem-carousel {
  margin-right: var(--emu-common-spacing-none);

  @include mq('large') {
    margin-right: 65px; //as per design
  }

  .tns-outer {
    .tns-ovh {
      height: 100% !important; // to override default styles which are causing content cut-off
    }
  }

  &__actions {
    position: absolute;
    bottom: -26px; //as per design
    right: 21px; //as per design

    @include mq('large') {
      width: 100%;
      display: flex;
      justify-content: space-between;
      top: 35%; //as per design
      bottom: initial;
      right: initial;
    }
  }

  &__action {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    bottom: -8px; // to match the design.
    padding: var(--emu-common-spacing-none);
    background-image: none;

    @include mq('large') {
      bottom: 50%;
    }

    &:disabled {
      opacity: 0;

      @include mq('large') {
        opacity: initial;
      }
    }

    svg {
      display: block;
      fill: var(--emu-common-colors-black);
      width: 24px;
      height: 24px;

      @include mq('large') {
        width: 20px;
        height: 20px;
      }
    }

    &-prev {
      margin: var(--emu-common-spacing-none);
      left: -20px; // to match the design.

      @include mq('large') {
        right: unset;
        left: -40px;
      }
    }

    &-next {
      right: -5px; // to match the design.

      @include mq('large') {
        right: -40px;
      }
    }
  }

  &__content {
    margin-left: auto;
    margin-right: auto;
  }
}
