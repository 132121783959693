/** 
 * This is a video-text-container component which displays video on the left side and text content on the right side. It is present in home and for professionals page.
 * Design link : https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-182&mode=design&t=1s0CCG8za3tt3aYv-4
 */

.video-text-container {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 27px;
    padding-bottom: 37px;
    padding-left: 24px;
    padding-right: 24px;

    @include mq('large') {
      flex-direction: row;
      padding-bottom: 34px;
      padding-left: 70px;
      padding-right: 70px;
    }

    @include mq('xx-large') {
      gap: 130px;
      justify-content: center;
    }

    > div:nth-child(1) {
      width: 345px;
      max-width: 100%;

      @include mq('large') {
        width: 636px;
        max-width: 50%;
      }
    }
  }

  &__video-content {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .embed {
      background-color: var(--emu-semantic-colors-secondary-100);
      padding: 15px;

      @include mq('large') {
        padding: 20px;
      }
    }
  }

  &__disclaimer-text {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-common-line-heights-narrow-medium);
    letter-spacing: -0.36px;

    @include mq('large') {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.42px;
    }
  }

  &__text-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 31px;

    @include mq('large') {
      gap: 48px;
      width: 518px;
      max-width: 100%;
    }

    h2 {
      margin-bottom: 26px;
      letter-spacing: -0.8px;

      @include mq('large') {
        margin-bottom: 48px;
        letter-spacing: -1.2px;
      }
    }

    // specificity needed to override default styles
    [data-component="text"] p {
      letter-spacing: -0.48px;

      @include mq('large') {
        letter-spacing: -0.72px;
      }
    }
  }

  &__cta {
    min-width: 228px;

    @include mq('large') {
      min-width: 271px;
    }
  }
}
