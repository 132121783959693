body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

sup {
  font-size: 50%;
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: var(--emu-common-spacing-none);
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}
