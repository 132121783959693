.button {
  text-align: center; // to match the design.

  .aaaem-button {
    font: var(--emu-component-actions-button-text-typography-narrow);
    letter-spacing: 3.2px; // As per the design.
    justify-content: center;
    padding-top: var(--emu-component-actions-button-padding-top-narrow);
    padding-right: var(--emu-component-actions-button-padding-right-narrow);
    padding-bottom: var(--emu-component-actions-button-padding-bottom-narrow);
    padding-left: var(--emu-component-actions-button-padding-left-narrow);

    @include mq('large') {
      font: var(--emu-component-actions-button-text-typography-wide);
      letter-spacing: 3.6px;
      padding-top: var(--emu-component-actions-button-padding-top-wide);
      padding-right: var(--emu-component-actions-button-padding-right-wide);
      padding-bottom: var(--emu-component-actions-button-padding-bottom-wide);
      padding-left: var(--emu-component-actions-button-padding-left-wide);
    }

    &__secondary {
      &-filled {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        letter-spacing: 3.6px; // As per the design.
      }

      &-outline {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: var(--emu-common-line-heights-narrow-medium);
        letter-spacing: 1.2px; // As per the design.

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-large);
          letter-spacing: 3.2px;
          padding-top: 17px;
          padding-bottom: 17px;
        }
      }
    }
  }
}
