/** 
 * This is a gallery-redirector component which displays before after image of the patients along with view gallery button. It is present in home and customized for you page.
 * Design link : https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-169&mode=design&t=mHtgcM09BlQw09Bb-4
 */

.gallery-redirector {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin: auto;
    padding-top: 43px;
    padding-bottom: 51px;
    padding-left: 20px;
    padding-right: 20px;

    @include mq('large') {
      flex-direction: row;
      padding-top: 53px;
      padding-bottom: 51px;
      padding-left: 112px;
      padding-right: 70px;
    }

    @include mq('xx-large') {
      gap: 200px;
      justify-content: center;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;

    @include mq('large') {
      gap: 42px;
      width: 423px;
      max-width: 100%;
    }

    h2 {
      margin-bottom: 23px;
      letter-spacing: -0.8px;

      @include mq('large') {
        margin-bottom: 33px;
        letter-spacing: -1.2px;
      }
    }

    // specificity needed to override default styles
    .cmp-text p {
      letter-spacing: -0.48px;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      max-width: 640px;

      @include mq('large') {
        letter-spacing: -0.72px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none)
      }
    }
  }

  &__cta {
    min-width: 248px;

    @include mq('large') {
      min-width: 285px;
    }
  }

  &__image-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 34px;
    align-items: center;

    @include mq('large') {
      gap: 29px;
    }
  }

  &__image-content {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @include mq('large') {
      gap: 29px;
    }

    .image {
      max-width: 168px;

      @include mq('medium') {
        max-width: 303px;
      }
    }
  }

  &__image-text {
    margin-top: 14px;
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 1.5;
    letter-spacing: 1.2px;

    @include mq('large') {
      margin-top: 17px;
      font-size: 15px;
      letter-spacing: 1.5px;
    }

    p {
      font-weight: var(--emu-common-font-weight-bold);

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-medium);
      }
    }
  }

  &__disclaimer-text {
    max-width: 577px;
    margin: auto;
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 15.6px;
    letter-spacing: -0.36px;
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      max-width: 577px;
    }

    p {
      @include mq('large') {
        padding-bottom: 25px;
      }
    }
  }
}
