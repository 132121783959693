#error {
  .error {
    &__page {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 80px;
      margin-left: auto;
      padding-left: 30px;
      padding-right: 30px;

      @include mq('large') {
        width: 60%; // to make it look good
        padding: var(--emu-common-spacing-none);
      }
    }

    &__title .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: 1.25;
      font-weight: var(--emu-common-font-weight-light);
      margin-bottom: 80px;

      @include mq('large') {
        font-size: 55px;
      }
    }
  }
}
