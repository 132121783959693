#treatment {
  .video-features-container {
    &__intro-text {
      max-width: 952px; //as per design
      margin-left: 30px; //as per design
      margin-right: 30px; //as per design
      margin-top: 34px; //as per design
      margin-bottom: 46px; //as per design

      @include mq('large') {
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px; //as per design
        margin-bottom: 98px; //as per design
      }

      h3 {
        font-size: var(--emu-common-font-sizes-narrow-xl);
        line-height: 30px; //as per design
        letter-spacing: -0.72px; //as per design

        @include mq('large') {
          font-size: 42px; //as per design
          line-height: 52.5px; //as per design
          letter-spacing: -1.26px; //as per design
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 28px; //as per design
      margin-left: 28px; //as per design
      margin-right: 28px; //as per design
      margin-bottom: 59px; //as per design
      justify-content: center;

      @include mq('large') {
        flex-direction: row;
        align-items: center;
        gap: var(--emu-common-spacing-xl);
        margin-left: var(--emu-common-spacing-large);
        margin-right: var(--emu-common-spacing-large);
        margin-bottom: 39px; //as per design
      }

      @include mq('x-large') {
        gap: 135px; //as per design
        align-items: flex-start;
        margin-left: 66px; //as per design
        margin-right: 66px; //as per design
      }
    }

    &--left {
      max-width: 100%;
      width: 336px; //as per design
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        width: 563px; //as per design
      }

      @include mq('x-large') {
        width: 643px; //as per design 
      }
    }

    &__video {
      border: 15px solid var(--emu-semantic-colors-secondary-100); //as per design
      background-color: var(--emu-semantic-colors-secondary-100);

      @include mq('large') {
        border-width: 20px; //as per design
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    &__title {
      @include mq('large') {
        margin-top: 35px; //as per design
      }

      h3 {
        font-family: var(--emu-semantic-font-families-body);
        font-size: 22px; //as per design
        line-height: 33px; //as per design
        letter-spacing: 0.66px; //as per design
        text-align: center;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-xl);
          line-height: var(--emu-common-line-heights-wide-xl);
          letter-spacing: 0.96px; //as per design
          text-align: left;
        }
      }
    }

    &__description {
      max-width: 640px; //as per design
      margin-left: var(--emu-common-spacing-xs);
      margin-right: var(--emu-common-spacing-xs);
      letter-spacing: -0.48px; //as per design
      margin-top: 11px; //as per design
      text-align: center;

      @include mq('large') {
        max-width: 518px; //as per design
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
        letter-spacing: -0.72px; //as per design
        margin-top: 14px; //as per design
        text-align: left;
      }

      p {
        font-weight: var(--emu-common-font-weight-regular);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 24px; //as per design
      margin-top: 10px; //as per design

      @include mq('large') {
        margin-top: 28px; //as per design
        gap: 45px; //as per design
        margin-left: -6px; //as per design
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 11px; //as per design

        @include mq('large') {
          flex-direction: row;
          gap: var(--emu-common-spacing-medium);
        }

        &-icon {
          max-width: 105px; //as per design
        }

        &-text {
          max-width: 640px;
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: 20.8px; //as per design
          letter-spacing: -0.48px; //as per design
          text-align: center;
          margin-left: var(--emu-common-spacing-small);
          margin-right: var(--emu-common-spacing-small);

          @include mq('large') {
            max-width: 390px; //as per design
            font-size: 18px; //as per design
            line-height: 23.4px; //as per design
            letter-spacing: -0.54px; //as per design
            text-align: left;
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
          }

          .emphasis {
            color: var(--emu-semantic-colors-primary-50);
            letter-spacing: 1.6px; //as per design

            @include mq('large') {
              letter-spacing: 1.8px; //as per design
            }
          }

          p {
            font-weight: var(--emu-common-font-weight-regular);
          }
        }

        &--last {
          margin-left: var(--emu-common-spacing-small);
          margin-right: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-left: var(--emu-common-spacing-none);
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }

  .image-text-overlay {
    &__teaser {
      @include mq('large') {
        margin-left: 66px; //as per design
        margin-right: 66px; //as per design
      }

      .content-container {
        padding-top: 42px; //as per design

        @include mq('large') {
          padding-top: 73px; //as per design
        }
      }

      .cmp-teaser {
        &__title {
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: -0.8px; //as per design
          padding-bottom: 21px; //as per design
          padding-top: var(--emu-common-spacing-none);
          text-shadow: none;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            letter-spacing: -1.2px; //as per design
          }

          br {
            @include mq('medium') {
              display: none;
            }
          }
        }

        &__description {
          margin-left: auto;
          margin-right: auto;
          max-width: 626px; //as per design

          p {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
            letter-spacing: -0.54px; //as per design

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-small);
              line-height: var(--emu-semantic-line-heights-wide-small);
              letter-spacing: -0.72px; //as per design
            }

            br {
              @include mq('medium') {
                display: none;
              }
            }
          }
        }
      }

      .disclaimer {
        left: 27px; //as per design
        color: var(--emu-common-colors-white);

        @include mq('large') {
          display: block;
          left: 31px; //as per design
          bottom: 24px; //as per design
        }
      }
    }
  }

  .dg-fap__teaser--small {
    margin-bottom: 23px; //as per design

    @include mq('large') {
      margin-top: 50px;
      margin-bottom: 60px; //as per design
    }
  }
}