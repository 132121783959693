.cmp-experiencefragment--ISI {
  max-width: var(--emu-component-containers-container-max-width);
  margin-left: auto;
  margin-right: auto;
}

.container-isi {
  .aaaem-isi {
    &-container {
      &__banner-content {
        p {
          margin: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
          font-size: 11px; // as per design
          line-height: 16.5px; // as per design
          letter-spacing: 0.33px; // as per design
          font-weight: var(--emu-semantic-font-weight-500);
          opacity: 0.9; // as per design

          @include mq('large') {
            font-size: 15px; // as per design
            line-height: 22.5px; // as per design
            letter-spacing: 0.45px; // as per design
          }
        }
      }

      &__button-wrapper {
        .aaaem-button {
          padding: var(--emu-common-spacing-none);
          color: var(--emu-common-colors-white);
          margin-top: 5px; // as per design
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: var(--emu-semantic-line-heights-narrow-small);
          letter-spacing: 0.48px; // as per design
          font-weight: var(--emu-semantic-font-weight-500);
          gap: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-top: 3px; // as per design
            font-size: 15px; // as per design
            line-height: 22.5px; // as per design
            letter-spacing: 0.45px; // as per design
          }

          .cmp-button__text {
            display: none;

            @include mq('large') {
              display: block;
            }
          }

          svg {
            width: 14px; // as per design
            stroke: var(--emu-common-colors-white);
            stroke-width: 1.5;
          }
        }
      }

      &__isi-content .aaaem-text {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: var(--emu-common-line-heights-wide-medium);
        letter-spacing: 0.48px; // as per design
        font-weight: var(--emu-common-font-weight-regular);
      }
    }
  }
}

.aaaem-back-to-top-button {
  width: 100%;
  background-color: var(--emu-common-colors-black);
  color: var(--emu-common-colors-white);
  justify-content: center;
  text-decoration: none;
  font-weight: var(--emu-semantic-font-weight-500);
  letter-spacing: 0.48px; // as per design
  padding: 11px; // as per design
  max-height: 44px; // as per design
  z-index: var(--emu-common-other-z-index-overlay);

  .cmp-button__text {
    opacity: 0.9; // as per design
  }

  @include mq('large') {
    font-size: 15px; // as per design
    line-height: 22.5px; // as per design
    letter-spacing: 0.45px; // as per design
  }
}
