/** 
 * This is an instagram component which displays instagram videos in a carousel with description. It is present in home , treatment , proven results , customized for you , faq and for professionals pages.
 * Design link : https://www.figma.com/file/yJVnDHoOvzheojQy9faMHt/023998-DiamondGlow-Website?type=design&node-id=1-53&mode=design&t=PTMmHONYBh4LWAR1-4
 */

.dg-instagram {
  &__section {
    padding-top: 27px;
    padding-bottom: 91px;
    background-color: var(--emu-common-colors-black);

    @include mq('large') {
      padding-top: 58px;
      padding-bottom: 42px;
    }

    .aaaem-carousel {
      margin: var(--emu-common-spacing-none);
      margin-left: 27px;

      @include mq('large') {
        margin-left: var(--emu-common-spacing-xl);
        margin-right: var(--emu-common-spacing-xl);
      }

      .tns-ovh.tns-ah {
        height: auto !important; // Overriding default carousel styles.
      }

      &__action {
        bottom: -35px; // to match the design.

        svg {
          fill: var(--emu-common-colors-white);
        }

        &-prev {
          left: -3px; // to match the design.

          @include mq('large') {
            right: unset;
            left: -43px;
          }
        }

        &-next {
          right: -10px; // to match the design.

          @include mq('large') {
            right: -43px;
          }
        }
      }
    }

    .aaaem-embed {
      border-radius: 20px;
      overflow: hidden;
    }
  }

  &__title {
    .cmp-title__text {
      color: var(--emu-common-colors-white);
      font-size: 23px;
      line-height: 35px;
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        font-size: 44px;
        line-height: 66px;
        margin-bottom: 66px;
      }
    }
  }

  &__text.aaaem-text {
    color: var(--emu-common-colors-white);
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    line-height: var(--emu-semantic-line-heights-narrow-xs);
    margin-top: 17px;

    @include mq('large') {
      font-size: 15px;
      line-height: 19px;
      margin-top: 24px;
    }

    a {
      color: inherit;
    }
  }
}
