[data-component='modal'] {
  &[data-id='jan-2024-alle-offer'] {
    .modal-wrapper {
      background-color: #0009;
    }

    .main-close {
      right: 0;
      top: -55px;

      &:hover,
      &:focus,
      & {
        opacity: 1;
      }

      span {
        color: var(--emu-common-colors-white);
        font-family: 'san-serif';
        font-size: 34px;
      }
    }

    .modal-content {
      max-width: 367px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      top: calc(50% + 10px);
      transform: translate(0, -50%);
      background-color: var(--emu-common-colors-transparent);

      @include mq('large') {
        max-width: 921px;
        margin: 15vh auto;
        top: unset;
        transform: translate(
          var(--emu-common-spacing-none),
          var(--emu-common-spacing-none)
        );
      }

      > div {
        position: relative;
        background-color: var(--emu-semantic-colors-secondary-100);
        aspect-ratio: 160 / 253;

        @include mq('large') {
          aspect-ratio: 1024 / 597;
        }
      }

      .background-image-mobile,
      .background-image {
        width: 100%;
      }

      .background-image-mobile {
        @include mq('large') {
          display: none;
        }
      }

      .background-image {
        display: none;

        @include mq('large') {
          display: block;
        }
      }

      .lock-up {
        display: flex;
        flex-direction: column;
        height: 100%;
        left: var(--emu-common-spacing-none);
        position: absolute;
        right: 0;
        top: var(--emu-common-spacing-none);
        z-index: 2;

        @include mq('large') {
          right: 50%;
        }
      }

      .main-text {
        height: var(--emu-common-sizing-xxs);
        margin: var(--emu-common-spacing-none);
        overflow: hidden;
        padding: var(--emu-common-spacing-none);
      }

      .button {
        display: flex;
        margin-left: auto;
        margin-right: auto;

        .aaaem-button {
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 102%;
          padding-bottom: 8px;
          padding-left: 28px;
          padding-right: 28px;
          padding-top: var(--emu-common-spacing-small);

          @include mq('large') {
            margin-top: 85%;
            padding-bottom: 11px;
            padding-left: 46px;
            padding-right: 46px;
            padding-top: 11px;
          }

          span {
            font-family: Arial, sans-serif;
            font-size: 17px;
            line-height: 17px;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-large);
              line-height: 20px;
            }
          }
        }
      }

      .disclaimer {
        height: var(--emu-common-sizing-xxs);
        margin: var(--emu-common-spacing-none);
        overflow: hidden;
        padding: var(--emu-common-spacing-none);
      }
    }
  }
}
