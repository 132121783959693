.product-grid {
  padding-top: 34px; //as per design
  padding-bottom: 34px; //as per design

  @include mq('large') {
    padding-top: 40px; //as per design
    padding-bottom: 40px; //as per design
  }
  
  &__title {
    .cmp-title__text {
      margin-bottom: 12px; //as per design
      padding-left: 19px; //as per design
      font-size: 14px; //as per design
      line-height: 1.5; //as per design
      letter-spacing: 0.42px; //as per design
      font-family: var(--emu-common-font-families-sans);

      @include mq('large') {
        width: 100%;
        padding-left: 68px; //as per design
        padding-right: 68px; //as per design
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        font-size: var(--emu-common-font-sizes-wide-medium);
        letter-spacing: 0.48px; //as per design
      }

      br {
        @include mq('medium') {
          display: none;
        }
      }
    }
  }

  &__main-grid {
    display: flex;
    gap: 14px; // as per design
    row-gap: 23px; // as per design
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;

    @include mq('large') {
      gap: 19px; // as per design
    }

    @include mq('xx-large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      margin-left: 65px; // as per design
      margin-right: 65px; // as per design
    }

    > .container {
      max-width: calc(50% - 10px);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 174px; // as per design
    max-width: 100%;

    @include mq('medium') {
      width: 313px; // as per design
    }
  }
}
