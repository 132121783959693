.emu-navigation {
  &__content-wrapper {
    display: block;
    position: static;

    nav {
      background-color: var(--emu-common-colors-transparent);
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
      padding-top: 26px;
      padding-bottom: 26px;

      @include mq('large') {
        border-top: none;
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
      }

      ul {
        align-items: flex-start;
        flex-direction: column;

        @include mq('large') {
          height: 100px;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }

        li {
          width: 100%;
          margin-bottom: 1px;

          @include mq('large') {
            width: auto;
            margin-bottom: var(--emu-common-spacing-none);
          }

          a {
            font-size: 19px;
            font-weight: var(--emu-common-font-weight-regular);
            line-height: 23px;
            letter-spacing: 0.19px;
            color: var(--emu-common-colors-black);
            border: none;
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 12px;
            padding-bottom: 12px;

            @include mq('large') {
              padding-top: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-none);
              border-bottom: 2px solid var(--emu-common-colors-transparent);
              font-size: 15px;
              letter-spacing: 0.15px;
              text-align: center;
            }

            &:hover {
              @include mq('large') {
                border-bottom-color: var(--emu-common-colors-black);
              }
            }

            sup {
              font-size: 70%;
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }

  .emu-navigation__item--active {
    a {
      font-weight: var(--emu-common-font-weight-bold);
    }
  }

  &.header__menu-navlinks--dark {
    background-color: var(--emu-semantic-colors-primary-50);
    border: none;
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);

    a {
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      padding-top: 26px;
      padding-right: 17px;
      padding-bottom: 27px;
      padding-left: 17px;
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-white);

      &:hover,
      &:active,
      &:focus,
      & {
        color: var(--emu-common-colors-white);
        border-bottom-color: var(--emu-common-colors-white);
        font-weight: var(--emu-common-font-weight-regular);
      }

      @include mq('large') {
        color: var(--emu-common-colors-black);
      }
    }

    li:last-child {
      a {
        font-weight: var(--emu-semantic-font-weight-500);
        border: none;
      }
    }
  }
}
